import { Timers } from "@yups/utils";
import Logger, { Event } from "helpers/Logger";
import { EventType } from "events/Event";

const FLUSHABLE_EVENTS = ["quick_response_selected"];
const MAX_EVENT_SIZE = 2500; // based on Queue.maxElementSize, used by Logger in yup-utils

type EventLogType = {
    name: string;
    timestamp: Date;
    data: any;
    count: number;
};
class EventLogClass {
    private eventLogs: EventLogType[] = [];

    private lastFlushIdx = 0;

    constructor() {
        Timers.setRecursiveTimeout({
            label: "event_log_flush",
            callback: this.clearOldLogs,
            delay: 10000
        });
    }

    get recentLogs() {
        return this.eventLogs
            .filter((log) => log.timestamp.getTime() > tenMinutesAgo())
            .map((log) =>
                formattedEventLog(log.timestamp, log.name, log.count)
            );
    }

    add(eventName: keyof EventType, timestamp: number, eventData: any) {
        const lastEvent = this.eventLogs[this.eventLogs.length - 1];
        if (lastEvent?.name === eventName) {
            lastEvent.count += 1;
        } else {
            this.eventLogs.push({
                name: eventName,
                timestamp: new Date(timestamp),
                data: eventData,
                count: 1
            });
        }

        this.flushSingleEvent(eventName, eventData);
    }

    reset() {
        this.eventLogs = [];
        this.lastFlushIdx = 0;
    }

    flushSingleEvent(eventName: keyof EventType, eventData: any) {
        if (!this.flushableEvent(eventName)) return;

        Logger.event(eventName, "", eventData);
    }

    private clearOldLogs = () => {
        if (this.lastFlushIdx < this.eventLogs.length) {
            const logsToClear = this.eventLogs.slice(this.lastFlushIdx);

            for (let batch of batchedLogs(logsToClear)) {
                Logger.event(Event.eventLogFlushed, "", { events: batch });
            }

            this.lastFlushIdx = this.eventLogs.length;
        }
    };

    private flushableEvent(eventName: keyof EventType) {
        return FLUSHABLE_EVENTS.filter((x) => x === eventName).length > 0;
    }
}

function batchedLogs(eventLogs: EventLogType[]) {
    let batches: EventLogType[][] = [];
    let currentBatch: EventLogType[] = [];
    let currentBatchSize = 0;

    for (let event of eventLogs) {
        const { validEvent, eventSize } = minimallySizedEvent(
            event,
            MAX_EVENT_SIZE
        );

        if (currentBatchSize + eventSize > MAX_EVENT_SIZE) {
            batches.push(currentBatch);
            currentBatch = [validEvent];
            currentBatchSize = eventSize;
        } else {
            currentBatch.push(validEvent);
            currentBatchSize += eventSize;
        }
    }
    if (currentBatch.length > 0) batches.push(currentBatch);

    return batches;
}

function minimallySizedEvent(event: EventLogType, maxSize: number) {
    let eventSize = JSON.stringify(event).length;
    let validEvent = event;

    if (eventSize > maxSize) {
        validEvent = Object.assign({}, event, {
            data: { error: "data was too large to flush" }
        });
        eventSize = JSON.stringify(validEvent).length;
    }
    return { validEvent, eventSize };
}

function formattedEventLog(timestamp: Date, name: string, count: number) {
    const time = JSON.stringify(timestamp).replace(/"/g, ""),
        displayCount = count > 1 ? count : null;

    return [time, name, displayCount].filter((x) => !!x).join(" ");
}

export function tenMinutesAgo() {
    return Date.now() - 10 * 60 * 1000;
}

export const EventLog = new EventLogClass();

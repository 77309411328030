import { Pusher } from "helpers/Pusher";
import { StorageManager } from "helpers/Storage";
import { clearUser as clearSentryUser } from "helpers/Sentry";
import { clearUserSession } from "helpers/UserSession";

export function cleanup() {
    Pusher.deinitialize();
    StorageManager.cleanup();
    clearUserSession();
    clearSentryUser();
}

import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const zoomOut: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.zoomOut();
        LogAction(context, WhiteboardAction.zoomOut);
    }
};

export default zoomOut;

import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const undo: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.undo();
        LogAction(context, WhiteboardAction.undo);
    }
};

export default undo;

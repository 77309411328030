import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Message, {
    dedupeMessages
} from "shared/components/ChatRoom/types/Message";
import { Session } from "shared/types/Session";

type PracticeSessionStateType = {
    session: Session | null;
    messages: Array<Message>;
};

const practiceSessionSlice = createSlice({
    name: "practiceSession",
    initialState: {
        session: null
    } as PracticeSessionStateType,
    reducers: {
        setSession: (state, action: PayloadAction<Session>) => {
            state.session = action.payload;
        },
        addMessage: (state, action: PayloadAction<Message>) => {
            if (!state.session) return;
            state.session.messages = dedupeMessages(
                [action.payload].concat(state.session.messages)
            );
        },
        reset: (state) => {
            state.messages = [];
            state.session = null;
        }
    }
});

export default practiceSessionSlice.reducer;

export const { setSession, addMessage, reset } = practiceSessionSlice.actions;

import { LogEvent, LocalStorageHelper } from "@yups/utils";
import Notifications from "./Notifications";
import { getUser, getUserLogInfo } from "models/User";
import { getShiftLogInfo } from "models/Shift";
import { getSessionLogInfo } from "models/Session";
import { EnvironmentHelper } from "@yups/utils";
import { NetworkHealthMonitor } from "./NetworkHealthMonitor";
import { Config } from "helpers/Config";
import Sentry from "./Sentry";
import FeatureDecision from "models/FeatureDecision";
import { Device } from "models/Device";
import { EventType } from "events/Event";

export enum Event {
    matchAssignmentNotification = "client_received_realtime_assignment_notification",
    matchAssignmentModal = "client_presented_new_match_alert",
    matchAssignmentCanceled = "client_received_match_canceled",
    matchAssignmentPassed = "client_received_match_passed",
    matchClaimRequest = "client_sent_claim_request_with_button",
    matchClaimResponse = "client_received_claim_response",
    matchClaimTimeout = "client_sent_claim_timeout_request",
    clientWindowFocus = "client_focused_window",
    clientWindowBlur = "client_blurred_window",
    sessionMessageSent = "client_session_message_sent_tutor",
    sessionMessageReceived = "client_session_message_received_real_time_tutor",
    sessionPollFinished = "client_session_poll_finished",
    sessionTutorJoinSuccess = "client_session_joined_channel_tutor",
    sessionTutorJoinFailure = "client_session_failed_to_join_channel_tutor",
    clientClickedWelcomeScreen = "client_clicked_welcome_screen",
    pusherSubscribedToChannel = "client_joined_channel",
    pusherSubscribedToChannelFailed = "client_joined_channel_failed",
    pusherConnected = "client_pusher_connected",
    pusherConnectionFailed = "client_pusher_connected_failed",
    pusherDisconnected = "client_pusher_disconnected",
    clientMessageRendered = "client_message_rendered_tutor",
    clientImageLoaded = "client_image_loaded_tutor",
    pusherChannelTriggerFailed = "client_student_pusher_trigger_failed",
    clientWindowClosedOrRefreshed = "client_window_closed_or_refreshed",
    storageIgnoredExpiredSession = "storage_ignored_expired_session",
    storageOverwroteExpiredSession = "storage_overwrote_expired_session",
    sessionFeedbackStarted = "client_session_feedback_started",
    sessionFeedbackAutoSubmitted = "client_session_feedback_auto_submitted",
    sessionCleared = "client_session_cleared",
    workbookResourceClicked = "tutor_workbook_resource_clicked",
    workbookSessionClicked = "tutor_workbook_session_clicked",
    sessionWhiteboardUsed = "session_whiteboard_used",
    sessionWhiteboardPageChanged = "session_whiteboard_page_changed",
    sessionGradingTimeTracked = "session_grading_time_tracked",
    eventLogFlushed = "tutor_dashboard.events_flushed",
    session_claim_option_chosen = "session_claim_option_chosen",
    debugSessionEndRedirect = "debug_session_end_redirect"
}

const storeValues: { [key: string]: Array<StoreValue> } = {};

export type MonitorPayload = {
    type: "time" | "increment";
    value: number;
    tags: string | Array<string>;
};

export enum Log {
    api = "tutor_dashboard.api",
    shift = "tutor_dashboard.shift",
    session = "tutor_dashboard.session"
}

export function getLogTagFromEndPoint(endPoint: string) {
    return endPoint
        .split("?")[0]
        .replace(/\//g, "_")
        .replace(/^_|_$|_\d+$/g, "");
}

function logEventWrapper(event: Log | string, payload: any) {
    try {
        LogEvent(event, payload);
    } catch (error) {
        console.error(error);
        Sentry.logError(error);
    }
}

const Logger = {
    event: function (
        evt: Event | keyof EventType,
        tag: string | Array<string> = "",
        payload: { monitor?: MonitorPayload; [x: string]: any } = {}
    ) {
        processLog(evt, tag, payload);
    },

    log: function (
        evt: Log,
        tag: string | Array<string> = "",
        params: any = {}
    ) {
        let defaults = {
            ...getShiftLogInfo(),
            ...getUserLogInfo(),
            ...getSessionLogInfo(),
            branch: EnvironmentHelper.env,
            commit: Config.commitRef,
            monitor: {
                type: "increment",
                tags: tag
            }
        };

        logEventWrapper(evt, { ...defaults, ...params });
    },

    logTime: function (
        evt: Log,
        tag: string | Array<string> = "",
        timestamp: Date,
        params: any = {}
    ) {
        const delta = new Date().getTime() - timestamp.getTime();
        let defaults = {
            ...getShiftLogInfo(),
            ...getUserLogInfo(),
            ...getSessionLogInfo(),
            branch: EnvironmentHelper.env,
            commit: Config.commitRef,
            monitor: {
                type: "time",
                value: delta,
                tags: tag
            }
        };

        logEventWrapper(evt, { ...defaults, ...params });
    },

    includeStoreValues: function (
        objectKey: string,
        values: Array<StoreValue>
    ) {
        storeValues[objectKey] = values;
    }
};

type StoreValue = {
    storeKey: string;
    logKey: string;
};

function processLog(
    name: string,
    tag: string | Array<string> = "",
    payload: any = {}
) {
    const defaultPayload: any = {
        ...defaults(),
        monitor: {
            type: "increment",
            tags: tag
        }
    };

    logEventWrapper(name, { ...defaultPayload, ...payload });
}

function defaults() {
    let sessionId = getSessionLogInfo().math_crunch_session_id,
        defaults: any = {
            branch: EnvironmentHelper.env,
            commit: Config.commitRef,
            dont_mixpanel: true,
            source: "tutor_dashboard",
            online: navigator.onLine,
            notifications_status: Notifications.isPermitted(),
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            platform: "webapp",
            client: "tutor",
            user_type: "Tutor",
            user_name: getUser()?.profile?.full_name,
            ...getShiftLogInfo(),
            ...getUserLogInfo(),
            ...getSessionLogInfo(),
            feature_toggles: FeatureDecision.toggleStatuses,
            device_id: Device.uuid
        };

    if (sessionId) {
        defaults.session_link = `https://go.yup.com/sr/${sessionId}`;
    }

    const addToDefaults = (object: any, src: string, key: string) => {
        if (!(src in object)) return;
        defaults[key] = object[src];
    };

    Object.keys(storeValues).forEach((key) => {
        const storeData = LocalStorageHelper.getSync(key);
        if (storeData) {
            const storeObject = JSON.parse(storeData);
            storeValues[key].forEach((value: StoreValue) => {
                addToDefaults(storeObject, value.storeKey, value.logKey);
            });
        }
    });

    return defaults;
}

NetworkHealthMonitor.eventPayloadCallback = () => defaults();

export default Logger;

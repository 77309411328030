import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { SessionReviewType } from "models/SessionReview";
import store from "store";
import { StoreKey } from "store/StoreKey";

const storePrefix = StoreKey.sessionReview;
const storage = new Storage(storePrefix, clearSessionReview);
function clearSessionReview(): void {
    reset();
}

type SessionReviewStateType = {
    sessionForReview: SessionReviewType | null;
    completedReview: SessionReviewType | null;
    error: string;
};

const sessionReviewSlice = createSlice({
    name: "sessionReview",
    initialState: {
        sessionForReview: storage.get() ?? null,
        completedReview: null,
        error: ""
    } as SessionReviewStateType,
    reducers: {
        setSessionForReview: (
            state,
            action: PayloadAction<SessionReviewType | null>
        ) => {
            state.sessionForReview = action.payload;

            updateCache(state);
        },
        setCompletedReview: (
            state,
            action: PayloadAction<SessionReviewType | null>
        ) => {
            state.completedReview = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        init: (state) => {
            state.sessionForReview = storage.get() ?? null;
        }
    }
});

export default sessionReviewSlice.reducer;

export const { setSessionForReview, setCompletedReview, setError, init } =
    sessionReviewSlice.actions;

export function reset() {
    store.dispatch(sessionReviewSlice.actions.setSessionForReview(null));
    store.dispatch(sessionReviewSlice.actions.setCompletedReview(null));
}

function updateCache(state: SessionReviewStateType) {
    if (state.sessionForReview) {
        storage.set(state.sessionForReview);
    } else {
        storage.remove();
    }
}

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";
import { Background } from "../types/Background";
import { WhiteboardAction } from "../types/WhiteboardAction";

export const background: BaseCommand = {
    async execute(
        context: ZwibblerContext,
        background: Background,
        color?: string
    ) {
        if (background === Background.color) {
            context.setConfig("background", color);
        } else if (background === Background.grid) {
            context.setConfig("background", "grid");
        } else if (background === Background.image) {
            context.setConfig("background", color);
            const result = await context.openFile({
                accept: "image/png,image/jpeg",
                format: "data-uri"
            });
            context.begin();

            // delete existing background, if present.
            const node = context.findNode("background"); // tag
            if (node) {
                context.deleteNodes(node);
            }
            context.insertImage({
                url: result.data,
                layer: "background",
                tag: "background"
            });
            context.commit();
        }
        LogAction(context, `${WhiteboardAction.background}_${background}`);
    }
};

export default background;

import { EnvironmentHelper, NetworkHelperClass, retry } from "@yups/utils";
import { Response } from "shared/types/Response";
import Usersnap from "helpers/Usersnap";
import Debug, { DebugLog } from "helpers/Debug";
import Sentry from "./Sentry";
import { MessageType } from "shared/components/ChatRoom/types/Message";
import { AuthStrategy } from "@yups/utils/build/AuthHelper/AuthHeaders";
import { onUnauthorized } from "models/User";
import { SubmitScorePayload } from "shared/types/SessionReview";
import { WorkbookSessionsRequest } from "./WebService";
import { CoveredTopic } from "shared/types/SessionFeedback";
const statusTag = {
    success: "success:true",
    error: "success:false"
};

enum Method {
    GET = "get",
    POST = "post",
    PUT = "put"
}

let retryCount = 0;
// This class will replace Webservice once all of the endpoints are fully migrated.
export class YupAPI {
    static networkHelper: NetworkHelperClass = new NetworkHelperClass();
    static init() {
        const url = () => {
            let env = EnvironmentHelper.env;
            if (env?.isDevelopment()) {
                return "http://localhost:3001";
            } else if (env?.isProduction()) {
                return "https://api.yupinternal.com";
            } else if (env?.isStandardEnvironment()) {
                return "https://yup-api-qa.herokuapp.com";
            } else if (env?.isDeveloperEnvironment()) {
                // See the yup-api project README on Github for instructions on how to setup ngrok
                return `https://${env.name}.ngrok.io`;
            }

            return "http://localhost:3001";
        };
        this.networkHelper.baseURL = url();
        this.networkHelper.authStrategy = AuthStrategy.BearerToken;
    }

    static setRetries(retries: number) {
        retryCount = retries;
    }

    static async sessionSendMessage(message: MessageType) {
        return await makeRequest(
            Method.POST,
            `/session/${message.chat_id}/send_message`,
            message
        );
    }

    static async serverTime() {
        return await makeRequest(Method.GET, "/settings/server_time");
    }

    static async sessionGetNewMessages(
        sessionId: number,
        latestMessageId: number
    ) {
        return await makeRequest(
            Method.GET,
            `/session/${sessionId}/latest-messages/${latestMessageId}`
        );
    }

    static async sessionSendLatestMessageId(
        sessionId: number,
        latestMessageId: number
    ) {
        return await makeRequest(
            Method.POST,
            `/session/${sessionId}/latest-messages/${latestMessageId}`
        );
    }

    static async getNewDeliveries(sessionId: number) {
        return await makeRequest(
            Method.GET,
            `/session/${sessionId}/latest-messages-status`
        );
    }

    static async submitSessionFeedback(
        sessionId: number,
        feedback: SubmitPostSessionFeedbackRequest
    ) {
        return await makeRequest(
            Method.PUT,
            `/session/${sessionId}/submit_post_session_feedback`,
            feedback
        );
    }

    static async getLibraryCards() {
        return await makeRequest(Method.GET, "/library_cards");
    }

    static async getWhiteboardCaptures(session_id: number) {
        return await makeRequest(Method.GET, `/whiteboard_captures`, {
            session_id
        });
    }

    static async saveWhiteboardCapture(
        session_id: number,
        url: string,
        captured_at: number
    ) {
        return await makeRequest(Method.POST, "/whiteboard_capture", {
            session_id,
            url,
            captured_at
        });
    }

    static async assignedMatchmaking() {
        return await makeRequest(Method.GET, `/matchmaking/assigned`);
    }

    static async matchClaim(matchmakingId: number) {
        return await makeRequest(
            Method.PUT,
            `/matchmaking/${matchmakingId}/claim`
        );
    }

    static async sessionGet(sessionId: number) {
        return await makeRequest(Method.GET, `/session/${sessionId}`, {
            user_type: "tutor"
        });
    }

    static async matchTimeout(matchmakingId: number) {
        return await makeRequest(
            Method.PUT,
            `/matchmaking/${matchmakingId}/pass`
        );
    }

    static async nextSessionToScore() {
        return await makeRequest(Method.PUT, "/rubric/score/next");
    }

    static async fetchScore(scoreId: number) {
        return await makeRequest(Method.GET, `/rubric/score/${scoreId}`);
    }

    static async submitScore(scoreId: number, payload: SubmitScorePayload) {
        return await makeRequest(
            Method.PUT,
            `/rubric/score/${scoreId}/submit`,
            payload
        );
    }
    static async getWorkbookSessions(payload: WorkbookSessionsRequest) {
        return await makeRequest(Method.GET, `/workbook/sessions`, payload);
    }

    static async tutorReady(sessionId: number) {
        return await makeRequest(
            Method.PUT,
            `/session/${sessionId}/tutor_ready`
        );
    }

    static async endSession(sessionId: number, params: EndSessionRequest) {
        return await makeRequest(
            Method.PUT,
            `/session/${sessionId}/end_session`,
            params
        );
    }

    static async submitPostSessionFeedback(
        sessionId: number,
        params: SubmitPostSessionFeedbackRequest
    ) {
        return await makeRequest(
            Method.PUT,
            `/session/${sessionId}/submit_post_session_feedback`,
            params
        );
    }
}

YupAPI.networkHelper.onError((error) => {
    if (error.endPoint?.includes("log_entries")) {
        return;
    }

    Usersnap.addErrorLog(
        `${new Date().toISOString()} [API Error] ${error.method} ${
            error.endPoint
        } threw error: ${statusTag.error} ${error.error?.message}`
    );

    Sentry.logError(error.error);
});

const inProgress: { [key: string]: boolean } = {};
async function makeRequest(
    method: Method,
    endPoint: string,
    params: any = {},
    disableParallelRequests: boolean = false
): Promise<Response> {
    const response: Response = { success: false };

    const retries = retryCount;
    retryCount = 0;

    if (inProgress[endPoint] && disableParallelRequests) {
        return { success: false };
    }

    inProgress[endPoint] = true;

    try {
        await retry(async () => {
            const ts = new Date();

            const result = await YupAPI.networkHelper[method]({
                endPoint,
                params
            });

            if (Debug.get(DebugLog.APIRequests)) {
                const delta = new Date().getTime() - ts.getTime();
                if (delta > 500) {
                    // highlight requests that take over 500ms in red
                    console.log(
                        `%c [API REQUEST] ${endPoint} - ${delta}ms`,
                        "color: red"
                    );
                } else {
                    console.log(`[API REQUEST] ${endPoint} - ${delta}ms`);
                }
            }

            if (!("success" in result)) {
                // if the success flag isn't set, use status_code === 200
                // to determine if the request was successful
                result.success = result.status_code === 200;
            }

            if (result.status_code === 401) {
                onUnauthorized();
            }

            response.data = result;
            response.success = Boolean(result.success);
        }, retries);
    } catch (error) {
    } finally {
        inProgress[endPoint] = false;
    }

    return response;
}

export type EndSessionRequest = {
    ended_at: number;
    ended_action: string;
};

export type SubmitPostSessionFeedbackRequest = {
    tutor_subtopic_id: number;
    tutor_comment_for_student?: string | null;
    student_engagement_score?: string | null;
    engagement_score: string;
    student_mastery: Array<CoveredTopic>;
};

export type TutorReadyRequest = {
    started_at: number;
    tutor_ready_at: number;
};

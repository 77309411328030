import { SessionTag } from "./SessionTag";

export type StrandComment = {
    strand_id: string;
    text: string;
};

// Targetting Rubric v5
export type SubmitScorePayload = {
    id: string;
    started_explaining: boolean;
    options: Array<string>;
    general_comment: string;
    tags: Array<string>;
    instruction_required: boolean;
    strand_comments: Array<StrandComment>;
    canned_comments: Array<string>;
};

type TransferableFeedback = {
    transferable_question: string;
    transferable_comment: string;
    transferable_feedback: string;
    question_id: number;
};

export type SessionReviewFeedback = {
    startedAsking: boolean | null;
    answerKey: { [key: number]: number };
    tagKey: {
        [key: string]: Array<SessionTag>;
    };
    generalFeedback: string;
    ignore?: boolean;
    transferable_feedback?: TransferableFeedback; // Rubric v4
    instructionRequired: boolean | null; // Rubric v5
    strandCommentKey: {
        [key: string]: string;
    }; // Rubric v5
    cannedComments: Array<number>; // Rubric v5
};

export const SessionReviewFeedbackDefaults = {
    tagKey: {},
    startedAsking: null,
    instructionRequired: null,
    generalFeedback: "",
    answerKey: {},
    strandCommentKey: {},
    cannedComments: []
};

import { pusherHelper } from "@yups/utils";
import LogEvent, { Event } from "helpers/Logger";
import Debug, { DebugLog } from "helpers/Debug";
import Sentry from "helpers/Sentry";

const pusherConfig: Pusher.Config = {
    activityTimeout: 3000
};

export class Pusher {
    static async initialize(): Promise<void> {
        if (Debug.get(DebugLog.Pusher)) {
            console.log("[INIT PUSHER]");
        }
        await pusherHelper.init(pusherConfig);
        pusherHelper.onConnected(() => LogEvent.event(Event.pusherConnected));
        pusherHelper.onError((error) => {
            LogEvent.event(Event.pusherConnectionFailed);
            Sentry.logError(new Error(`Pusher connection failed: ${error}`));
        });
        pusherHelper.onDisconnected(() => {
            LogEvent.event(Event.pusherDisconnected);
        });
    }

    static deinitialize(): void {
        if (Debug.get(DebugLog.Pusher)) {
            console.log("[DEINIT PUSHER]");
        }
        pusherHelper.clear();
    }
}

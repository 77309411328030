import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const equation: BaseCommand = {
    async execute(context: ZwibblerContext, latexImg: string) {
        context.insertImage({ url: latexImg });
        LogAction(context, WhiteboardAction.equation);
    }
};

export default equation;

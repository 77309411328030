import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const select: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.usePickTool();
    }
};

export default select;

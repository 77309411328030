import store from "store/index";
import {
    ConnectionState,
    setConnectionState,
    setCurrentPage,
    isCurrentPageLive,
    setPages,
    setShowPages
} from "store/Whiteboard";
import { Config } from "helpers/Config";

import { getSessionInfo } from "models/Session";
import {
    ZwibblerClass,
    ZwibblerContext
} from "shared/components/Whiteboard/WhiteboardService/zwibbler2";
import { Page } from "shared/components/Whiteboard/types/Page";
import { WhiteboardAction } from "shared/components/Whiteboard/types/WhiteboardAction";
import { commands } from "shared/components/Whiteboard/commands/commands";

import UploadImage from "helpers/FileUpload";
import { getUser } from "./User";

declare let Zwibbler: ZwibblerClass;

class WhiteboardClass {
    context: ZwibblerContext | null = null;
    pages: Array<Page> = [];
    onPageUpdateCallback: (pages: Array<Page>) => void = () => {};

    onPageUpdate(callback: (pages: Array<Page>) => void) {
        this.onPageUpdateCallback = callback;
    }
    onCaptureCallback: (url: string) => void = () => {};

    attachZwibbler(element: HTMLElement): void {
        const scope = Zwibbler.attach(element, {
            collaborationServer: Config.zwibblerServerUrl
        });
        this.context = scope.ctx;

        // Listen for connected and connect-error events to show the status on the screen
        this.context?.on("connected", () => {
            store.dispatch(setConnectionState(ConnectionState.connected));
            this.updateAllPages();
        });
        this.context?.on("connect-error", () =>
            store.dispatch(setConnectionState(ConnectionState.inError))
        );

        // Join or create the live whiteboard session
        const sessionName = getSessionInfo().sessionChannel;
        if (sessionName) {
            this.context?.joinSharedSession(sessionName, true);
        } else {
            store.dispatch(
                setConnectionState(ConnectionState.connectionNotPossible)
            );
        }
    }

    detachZwibbler() {
        this.context?.destroy();
    }

    init() {
        this.updateAllPages();
        const currentPage = this.getCurrentPage();
        store.dispatch(setCurrentPage(currentPage));
        this.context?.setConfig("autoPickTool", false);
        this.context?.setConfig("autoPickToolText", false);
    }

    drawPage(page: number) {
        if (this.context) {
            const canvas = document.createElement("canvas");
            const size = this.context.getViewRectangle();
            canvas.width = size.width;
            canvas.height = size.height;
            const canvasContext = canvas.getContext("2d");
            if (canvasContext) {
                canvasContext.translate(-size.x, -size.y);
                this.context.draw(canvasContext, { page });
                return canvas.toDataURL();
            }
        }
    }

    updateAllPages() {
        if (this.context) {
            const newPages: Array<Page> = [];
            for (let page = 0; page < this.context.getPageCount(); ++page) {
                const image = this.drawPage(page);
                if (image) {
                    newPages.push({
                        id:
                            this.pages[page]?.id ||
                            Math.random().toString(36).substring(7),
                        image
                    });
                }
            }
            this.pages = newPages;
            this.onPageUpdateCallback(newPages);
        }
    }

    updateCurrentPage() {
        if (this.context) {
            const page = this.getCurrentPage();
            const image = this.drawPage(page);
            if (image) {
                this.pages[page] = {
                    ...this.pages[page],
                    image
                };
                this.pages = [...this.pages];
                this.onPageUpdateCallback(this.pages);
            }
        }
    }

    setPages(pages: Array<Page>) {
        store.dispatch(setPages(pages));
    }

    getCurrentPage() {
        return this.context?.getCurrentPage() || 0;
    }

    isCurrentPageLive(): boolean {
        return isCurrentPageLive();
    }

    execute(command: WhiteboardAction, args?: Array<any>) {
        if (this.context) {
            commands[command].execute(this.context, ...(args || []));
        }
        const currentPage = this.getCurrentPage();
        store.dispatch(setCurrentPage(currentPage));
        this.updateAllPages();
    }

    hasChanged(): boolean {
        return this.context!.dirty();
    }

    save() {
        this.context!.dirty(false);
    }

    showPages(show: boolean) {
        store.dispatch(setShowPages(show));
    }

    onCapture(callback: (url: string) => void) {
        this.onCaptureCallback = callback;
    }

    async saveCapture() {
        const user = getUser();
        if (!this.context || !user) return;
        const result = await fetch(this.context.save("png"));
        const blob = await result.blob();
        this.onCaptureCallback(
            await UploadImage.send(blob, user.id, "image/png")
        );
    }
}

export const Whiteboard = new WhiteboardClass();

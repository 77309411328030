import WebService from "helpers/WebService";
import { pusherHelper } from "@yups/utils";

import { Response } from "shared/types/Response";
import { Tutor } from "shared/types/Tutor";
import {
    Session as SessionType,
    SessionUpdateTypes
} from "shared/types/Session";
import Logger, { Event } from "helpers/Logger";
import Sentry from "helpers/Sentry";
import { PusherChannel } from "@yups/utils";
import { MessageType } from "shared/components/ChatRoom/types/Message";
import Session from "models/Session";
import { Activity } from "shared/components/ChatRoom/types/Activity";
import { SubmitPostSessionFeedbackRequest } from "helpers/YupAPI";

enum ErrorMessages {
    errorFailedToFetch = "Failed to fetch session.",
    errorFailedToUpdate = "Failed to update session.",
    errorFailedToMarkAsReady = "Failed to start the session. Please try again in a few seconds.",
    errorFailedToSendMessage = "Failed to send the message. Please try again in a few seconds.",
    errorFailedToEndSession = "Failed to end the session. Please try again in a few seconds.",
    errorFailedToSubmitFeedback = "There was an error submitting your feedback. Please reload and try again."
}

export enum SessionEvents {
    tutorFound = "client-tutor-found",
    tutorReady = "client-tutor-ready",
    sessionEnded = "client-session-ended",
    startedDrawing = "client-started-drawing",
    typingStarted = "client-is-typing",
    sendMessage = "client-message-sent",
    messageReceived = "client-message-sent",
    handshakeReceived = "client-received-handshake",
    handshakeRequested = "client-requesting-handshake",
    onSessionError = "on-session-error"
}

export type SessionUpdate = "tutorReady" | "endSession" | "feedback";

const pusherEvents = [
    { event: SessionEvents.sessionEnded, callback: onSessionEnded },
    { event: SessionEvents.typingStarted, callback: onTypingStarted },
    { event: SessionEvents.startedDrawing, callback: onDrawingStarted },
    { event: SessionEvents.handshakeRequested, callback: onHandshakeRequested }
];

let channel: PusherChannel | null = null;

const SessionNetwork = {
    async get(sessionId: number): Promise<Response> {
        const response = await WebService.sessionGet(sessionId);
        if (!response.success) {
            response.message = ErrorMessages.errorFailedToFetch;
        }
        return response;
    },

    async update(
        sessionId: number,
        info: SessionUpdateTypes,
        updateType: SessionUpdate
    ): Promise<Response> {
        WebService.setRetries(2);
        const response = await WebService.sessionUpdate(
            sessionId,
            info,
            updateType
        );
        if (!response.success) {
            response.message = ErrorMessages.errorFailedToUpdate;
        }
        return response;
    },

    async startSessionListeners(channelName: string): Promise<void> {
        try {
            channel = await pusherHelper.subscribeToChannel(channelName);
            Logger.event(Event.pusherSubscribedToChannel, "", {
                channel: channelName
            });
            pusherEvents.forEach((event) =>
                channel?.bind(event.event, event.callback)
            );
            Logger.event(Event.sessionTutorJoinSuccess);
        } catch (error) {
            Logger.event(Event.pusherSubscribedToChannelFailed, "", {
                channel: channelName
            });
            Logger.event(Event.sessionTutorJoinFailure);
            Sentry.logError(error);
        }
    },

    stopSessionListeners(): void {
        if (channel) {
            pusherHelper.unsubscribeFromChannel(channel);
            channel = null;
        }
    },

    async endSession(
        sessionId: number,
        ended_at: number,
        ended_action: string
    ): Promise<Response> {
        const response = await this.update(
            sessionId,
            { ended_action, ended_at },
            "endSession"
        );
        if (response.success) {
            triggerPusherChannel(SessionEvents.sessionEnded, {
                math_crunch_session_id: sessionId,
                ended_at,
                ended_action
            });
        } else {
            response.message = ErrorMessages.errorFailedToEndSession;
        }
        return response;
    },

    async submitFeedback(
        sessionId: number,
        feedback: SubmitPostSessionFeedbackRequest
    ) {
        const response = await WebService.submitSessionFeedback(
            sessionId,
            feedback
        );
        if (!response.success) {
            response.message = ErrorMessages.errorFailedToSubmitFeedback;
        }
        return response;
    },

    tutorFound(sessionId: number, tutor: Tutor): void {
        triggerPusherChannel(SessionEvents.tutorFound, {
            math_crunch_session_id: sessionId,
            user_type: "tutor",
            tutor: tutor
        });
    },

    startedTyping(): void {
        triggerPusherChannelThrottled(SessionEvents.typingStarted, 1000, {
            data: { typing: true }
        });
    },

    startedDrawing(): void {
        triggerPusherChannelThrottled(SessionEvents.startedDrawing, 1000, {
            data: { drawing: true }
        });
    },

    async tutorReady(sessionId: number, tutor: Tutor): Promise<Response> {
        const response = await WebService.sessionTutorReady(sessionId);

        if (response.success) {
            triggerPusherChannel(SessionEvents.tutorReady, {
                math_crunch_session_id: sessionId,
                user_type: "tutor",
                tutor: tutor
            });
        } else {
            response.message = ErrorMessages.errorFailedToMarkAsReady;
        }
        return response;
    },

    sendMessage(message: MessageType): void {
        triggerPusherChannel(SessionEvents.sendMessage, message);
    },

    async captureWhiteboard(
        sessionId: number,
        url: string,
        capturedAt: number
    ): Promise<Response> {
        return await WebService.saveWhiteboardCapture(
            sessionId,
            url,
            capturedAt
        );
    }
};

export default SessionNetwork;

function triggerPusherChannel(event: string, args?: object) {
    const success = channel?.trigger(event, args);
    if (!success) {
        Logger.event(Event.pusherChannelTriggerFailed, "pusher", { event });
        Sentry.logError(new Error(`Pusher channel event failed: ${event}`));
    }
}

function triggerPusherChannelThrottled(
    event: string,
    delay: number,
    args?: object
) {
    channel?.triggerThrottled(event, delay, args);
}

/*
 * Pusher callbacks.
 * ----------------------------
 */

function onSessionEnded(data: SessionType) {
    Session.studentEndedSession(data);
}

function onTypingStarted() {
    Session.setStudentActivity(Activity.typing);
}

function onDrawingStarted() {
    Session.setStudentActivity(Activity.drawing);
}

function onHandshakeRequested() {
    triggerPusherChannel(SessionEvents.handshakeReceived);
}

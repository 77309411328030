import AWS from "aws-sdk";
import { format } from "date-fns";
import Debug from "helpers/Debug";

interface S3Credentials {
    accessKeyId: string;
    secretAccessKey: string;
    region: string;
}

type InputFileConfig = {
    name: string;
    accepts: string;
    multiple: boolean;
};

const yearInSeconds = 31536000;

/**
 * File upload utility (uploading to S3).
 * Default export, exported as a singleton.
 */
class UploadFileClass {
    client = new AWS.S3(this.credentials);
    progress: number = 0;

    async send(
        imageData: File | Blob,
        userId: number,
        contentType: string,
        onProgress: Function | null = null
    ) {
        const params: any = {
            Body: imageData,
            Bucket: this.bucketName,
            Key: this.filePath(userId),
            ContentType: contentType
        };
        const managedUpload = new AWS.S3.ManagedUpload({
            params,
            service: this.client
        });
        return new Promise<string>((resolve, reject) => {
            managedUpload
                .on(
                    "httpUploadProgress",
                    (event: AWS.S3.ManagedUpload.Progress) => {
                        this.progress = (event.loaded * 100) / event.total;
                        onProgress?.(this.progress);
                    }
                )
                .send((error: Error) => {
                    delete params.Body;
                    delete params.ContentType;
                    if (error) {
                        reject(error);
                    } else {
                        params.Expires = yearInSeconds;
                        this.progress = 0;
                        resolve(this.client.getSignedUrl("getObject", params));
                    }
                });
        });
    }

    private get bucketName(): string {
        return Debug.isDevelopment ? "math-crunch-dev" : "math-crunch";
    }

    private get credentials(): S3Credentials {
        return {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY as string,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY as string,
            region: process.env.REACT_APP_S3_REGION as string
        };
    }

    private filePath(userId: number): string {
        const timestamp = Math.ceil(new Date().getTime() / 1000);
        const path = format(new Date(), "yyyy/MM/dd");
        return `problem-images/${path}/problem_image_${userId}_${timestamp}.jpg`;
    }
}

const UploadFile = new UploadFileClass();
export default UploadFile;

/**
 * Hidden input file wrapper.
 */
class InputFile {
    inputFile: HTMLInputElement;

    constructor(config: InputFileConfig) {
        this.inputFile = document.createElement("input");
        this.inputFile.setAttribute("type", "file");
        this.inputFile.setAttribute("id", config.name);
        this.inputFile.setAttribute("accept", config.accepts);
        this.inputFile.setAttribute("name", config.name);
        if (config.multiple) {
            this.inputFile.setAttribute("multiple", "");
        }
        this.inputFile.style.display = "none";

        document.body.appendChild(this.inputFile);
    }

    selectFiles(callback: Function) {
        this.inputFile.onchange = (event) => {
            const files = this.inputFile.files;
            if (files) {
                callback(files);
            }
        };
        this.inputFile.click();
    }
}

export { InputFile };

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";
import { Format } from "../types/Format";

export const upload: BaseCommand = {
    execute(context: ZwibblerContext, format?: Format) {
        if (format === Format.yup) {
            context.openFromComputer("yup");
            LogAction(context, "upload_yup");
        } else {
            context.insertImage();
            LogAction(context, "upload_image");
        }
    }
};

export default upload;

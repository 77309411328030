import { Timers } from "@yups/utils";

export default class Timer {
    label: string;
    remaining: number;
    seconds: number;

    constructor(label: string, seconds: number) {
        this.label = label;
        this.remaining = this.seconds = seconds;
    }

    start(onUpdate: (tick: number) => void, onExpire: () => void) {
        if (Timers.hasTimer(this.label)) return;
        onUpdate(this.remaining); // Start it immediately
        Timers.setInterval({
            label: this.label,
            callback: () => {
                if (!this.remaining) {
                    onExpire();
                    this.reset();
                    this.stop();
                    return;
                }
                --this.remaining;
                onUpdate(this.remaining);
            },
            delay: 1000
        });
    }

    stop() {
        Timers.clear(this.label);
    }

    reset() {
        this.remaining = this.seconds;
    }

    set(seconds: number) {
        this.remaining = seconds;
    }
}

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogPageChange } from "./BaseCommand";

export const selectPage: BaseCommand = {
    execute(context: ZwibblerContext, page: number) {
        context.setCurrentPage(page);
        LogPageChange(page, "selected");
    }
};

export default selectPage;

import { format } from "date-fns";

export function interpolateString(
    str: string,
    variables: { [key: string]: string }
) {
    const originalStr = str;
    const iter = str.matchAll(/{{\s*(\w+)\s*}}/g);
    let group = iter.next();
    while (!group.done) {
        const [match, key] = group.value;
        if (key in variables) {
            str = str.replace(match, variables[key]);
        } else {
            console.warn(
                `interpolateString: ${key} was not provided for string '${originalStr}'`
            );
        }
        group = iter.next();
    }
    return str;
}

export const formatTimestamp = (ts: number, dateFormat?: string) => {
    if (!ts) {
        return "N/A";
    }
    return format(new Date(ts * 1000), dateFormat ?? "MMM dd, yyyy, hh:mm:ssa");
};

export const formatTime = (time: number) => {
    let seconds = time % 60,
        minutes = (time / 60) % 60,
        hours = time / 3600;

    hours = Math.floor(hours);
    minutes = Math.floor(minutes);
    seconds = Math.floor(seconds);

    const timeStrings = [];
    if (hours) {
        timeStrings.push(`${hours} ${pluralize(hours, "hour")}`);
    }

    if (minutes) {
        timeStrings.push(`${minutes} min`);
    }

    if (seconds) {
        timeStrings.push(`${seconds} sec`);
    }
    return timeStrings.join(", ") || "0 seconds";
};

export const pluralize = (count: number, word: string) => {
    return count === 1 ? word : `${word}s`;
};

export const capitalize = (word: string) => {
    return `${word[0].toUpperCase()}${word.slice(1)}`;
};

export const formatPercent = (percent: number) => {
    return `${(percent * 100).toFixed()}%`;
};

export const formatRating = (rating: number | null) => {
    if (rating == null) return "N/A";
    else if (rating > 3) return "👍";
    else return "👎";
};

export const MinPasswordLength = 10;
export enum PasswordErrors {
    invalidLength,
    missingNumber,
    missingCapitalLetter,
    missingSymbol
}

export function isValidPassword(password: string): Array<PasswordErrors> {
    const errors = [];
    if (password.trim().length < MinPasswordLength) {
        errors.push(PasswordErrors.invalidLength);
    }
    if (!/\d/.test(password)) {
        errors.push(PasswordErrors.missingNumber);
    }
    if (!/[A-Z]/.test(password)) {
        errors.push(PasswordErrors.missingCapitalLetter);
    }
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/.test(password)) {
        errors.push(PasswordErrors.missingSymbol);
    }
    return errors;
}

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const uploadImage: BaseCommand = {
    execute(context: ZwibblerContext, url: string) {
        if (url.startsWith("data:image")) {
            context.insertImage({ url });
        } else {
            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const canvasContext = canvas.getContext("2d");
                canvasContext?.drawImage(image, 0, 0);
                context.insertImage({
                    url: canvas.toDataURL("image/png")
                });
                canvas.remove();
            };
            image.src = `${url}&_=${Math.random()}`;
        }
    }
};

export default uploadImage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { ShiftType } from "models/Shift";
import { Match } from "shared/types/Match";
import store from "store";
import { StoreKey } from "store/StoreKey";

const storePrefix = StoreKey.shift;
const storage = new Storage(storePrefix, clearShift);
function clearShift(): void {
    store.dispatch(shiftSlice.actions.setCurrent(null));
}

const counterStorage = new Storage("yup-shift-counters");

type ShiftStateType = {
    current: ShiftType | null;
    matchAssignment: Match | null;
    error: string;
    passed: number;
    accepted: number;
    timeSpentGrading: number;
};

const shiftSlice = createSlice({
    name: "shift",
    initialState: {
        current: null,
        matchAssignment: null,
        error: "",
        passed: 0,
        accepted: 0,
        timeSpentGrading: 0
    } as ShiftStateType,
    reducers: {
        setCurrent: (state, action: PayloadAction<ShiftType | null>) => {
            if (action.payload) {
                state.current = action.payload;
                const counters = counterStorage.get();
                state.accepted =
                    counters?.accepted ??
                    action.payload?.accepted_sessions_count;
                state.passed =
                    counters?.passed ?? action.payload?.passed_sessions_count;
            } else {
                state.accepted = 0;
                state.passed = 0;
                state.current = null;
                state.timeSpentGrading = 0;
            }

            updateCache(state);
        },
        setMatchAssignment: (state, action: PayloadAction<Match | null>) => {
            state.matchAssignment = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        incrementPassed: (state) => {
            state.passed++;
        },
        incrementAccepted: (state) => {
            state.accepted++;
        },
        incrementTimeSpentGrading: (state, action: PayloadAction<number>) => {
            state.timeSpentGrading += action.payload;
        },
        init: (state) => {
            if (!state.current) {
                state.current = storage.get();
            }
            if (counterStorage.get()) {
                state.passed = counterStorage.get().passed;
                state.accepted = counterStorage.get().accepted;
            }
        }
    }
});

export default shiftSlice.reducer;

export const {
    setCurrent,
    setMatchAssignment,
    setError,
    incrementPassed,
    incrementAccepted,
    incrementTimeSpentGrading,
    init
} = shiftSlice.actions;

function updateCache(state: ShiftStateType) {
    if (state.current) {
        storage.set(state.current);
        counterStorage.set({
            accepted: state.accepted,
            passed: state.passed
        });
    } else {
        storage.remove();
        counterStorage.remove();
    }
}

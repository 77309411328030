import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const doubleArrow: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.useArrowTool({
            arrowStyle: "solid",
            doubleArrow: true
        });
    }
};

export default doubleArrow;

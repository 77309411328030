import { Observable, Timers } from "@yups/utils";
import { Config } from "helpers/Config";

export enum NotificationsPermission {
    Denied = "denied",
    Granted = "granted",
    Default = "default"
}

export enum NotificationAudio {
    Match = "match"
}

export type NotificationOptions = {
    onClick?: Function;
    closeAfter?: number;
    audio?: NotificationAudio;
    backupText?: string;
};

const { assetsURL } = Config;
const img = `${assetsURL}/yup-icon-128.png`;
const audio = {
    [NotificationAudio.Match]: new Audio(`${assetsURL}/match-notification.mp3`)
};

const observable = new Observable("Notifications");

export enum NotificationsEvent {
    onPermissionChange = "permission-change"
}

if ("permissions" in navigator) {
    navigator.permissions
        .query({ name: "notifications" })
        .then((permission) => {
            permission.onchange = function () {
                observable.publish(NotificationsEvent.onPermissionChange, {
                    state: permission.state
                });
            };
        });
}

const Notifications = {
    isPermitted: function () {
        if ("Notification" in window) {
            return Notification.permission === NotificationsPermission.Granted;
        }
        return false;
    },

    isBlocked: function () {
        if ("Notification" in window) {
            return Notification.permission === NotificationsPermission.Denied;
        }
        return false;
    },

    requestPermission: function () {
        if ("Notification" in window && !this.isPermitted()) {
            Notification.requestPermission();
        }
    },

    sendNotification: async function (
        title: string,
        text: string,
        options: NotificationOptions = {}
    ) {
        if (options.audio) audio[options.audio].play();

        if ("serviceWorker" in navigator) {
            const registration =
                await navigator.serviceWorker.getRegistration();
            await registration?.showNotification(title, {
                body: options.backupText,
                icon: img
            });
        } else if ("Notification" in window) {
            const notification = new Notification(title, {
                body: text,
                icon: img
            });

            notification.onclick = (event) => {
                event.preventDefault();
                window.focus();
                if (options.onClick) options.onClick();
            };

            Timers.setTimeout({
                label: "hide-notification",
                callback: () => notification.close(),
                delay: options.closeAfter || 5000
            });
        }
    }
};

export default Notifications;

const NotificationsObservable = {
    subscribe: function (
        context: any,
        event: NotificationsEvent,
        callback: Function
    ) {
        observable.subscribe(context, event, callback);
    },
    unsubscribe: function (context: any, event: NotificationsEvent) {
        observable.unsubscribe(context, event);
    }
};

export { NotificationsObservable };

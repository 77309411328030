import SessionNetwork from "./SessionNetwork";
import { SessionMessages } from "./SessionMessages";
import { PusherChannel, pusherHelper } from "@yups/utils";
import { serverTime } from "helpers/SyncTime";
import { Response } from "shared/types/Response";
import { MessageSender } from "shared/components/ChatRoom/types/Message";
import { EndedAction } from "shared/types/EndedAction";
import { Timers } from "@yups/utils";
import Logger, { Event as LoggerEvent } from "helpers/Logger";
import Timer from "helpers/Timer";
import {
    Session as SessionType,
    isReviewing,
    isGivingFeedback,
    isCompleted,
    isEnded
} from "shared/types/Session";
import store from "store/index";
import {
    setCurrent,
    setError,
    setMessages,
    isMobileStudent
} from "store/Session";
import {
    setWantsToEndSession,
    setProcessingSession,
    setStudentActivity
} from "store/UI";
import { getUser } from "models/User";
import { Activity } from "shared/components/ChatRoom/types/Activity";
import Sentry from "helpers/Sentry";
import { Event } from "events/Event";
import UploadImage from "helpers/FileUpload";
import { Whiteboard } from "./Whiteboard";
import { SubmitPostSessionFeedbackRequest } from "helpers/YupAPI";

export enum EventListeners {
    onSessionUpdate = "on-session-update"
}

const sessionSyncTimerLabel = "session-sync";

const activityTimer = new Timer("student-activity", 10);

export const Intervals = {
    connectedSyncInterval: 15000,
    disconnectedSyncInterval: 3000
};

const Session = {
    currentInterval: 0,
    isSyncing: false,

    set(sessionInfo: SessionType): void {
        const session = currentSession();
        try {
            if (session === null || session.id === sessionInfo.id) {
                store.dispatch(setCurrent(sessionInfo));
            } else if (session.id < sessionInfo.id) {
                store.dispatch(setCurrent(sessionInfo));
                store.dispatch(setMessages(sessionInfo.messages));
                Logger.event(
                    LoggerEvent.storageOverwroteExpiredSession,
                    "session.set",
                    {
                        cached_session_id: session.id,
                        given_session_id: sessionInfo.id
                    }
                );
            } else {
                Logger.event(
                    LoggerEvent.storageIgnoredExpiredSession,
                    "session.set",
                    {
                        cached_session_id: session.id,
                        given_session_id: sessionInfo.id
                    }
                );
                throw new Error(
                    `Ignored attempt to overwrite session ${session.id} by ${sessionInfo.id}`
                );
            }
            this.sync();
        } catch (error) {
            console.error(error);
            Sentry.logError(error);
        }
        Event.dispatch("session_updated");
    },

    async startSessionListeners(): Promise<void> {
        const current = currentSession();
        if (!current) return;

        await this.sync();
        this.setSyncSessionInterval(Intervals.connectedSyncInterval);

        await SessionNetwork.startSessionListeners(current.channel_name);
        pusherHelper.onConnected(() =>
            this.setSyncSessionInterval(Intervals.connectedSyncInterval)
        );
        pusherHelper.onError(() =>
            this.setSyncSessionInterval(Intervals.disconnectedSyncInterval)
        );
        pusherHelper.onDisconnected(() =>
            this.setSyncSessionInterval(Intervals.disconnectedSyncInterval)
        );

        this.tutorFound();
    },

    stopSessionListeners(): void {
        SessionNetwork.stopSessionListeners();
        Timers.clear(sessionSyncTimerLabel);
    },

    tutorFound(): void {
        const current = currentSession();
        if (isReviewing(current))
            SessionNetwork.tutorFound(current!.id, current!.tutor);
    },

    async tutorReady(): Promise<boolean> {
        const current = currentSession();
        if (!current) return true;
        const response = await SessionNetwork.tutorReady(
            current.id,
            current.tutor
        );
        if (response.success) {
            store.dispatch(
                setCurrent({
                    ...current,
                    started_at: serverTime(),
                    tutor_ready_at: serverTime()
                })
            );
        } else {
            store.dispatch(setError(response.message ?? ""));
        }
        return response.success;
    },

    startedTyping(): void {
        SessionNetwork.startedTyping();
    },

    startedDrawing(): void {
        // With the live whiteboard, startedDrawing event is restricted to the live page
        // TODO: live whiteboard is not supported yet on mobile apps
        if (isMobileStudent() || Whiteboard.isCurrentPageLive())
            SessionNetwork.startedDrawing();
    },
    setStudentActivity(activity: Activity) {
        store.dispatch(setStudentActivity(activity));
        activityTimer.stop();
        activityTimer.reset();
        activityTimer.start(
            () => {},
            () => {
                const { studentActivity } = store.getState().ui;
                if (
                    studentActivity &&
                    [Activity.typing, Activity.drawing].includes(
                        studentActivity
                    )
                ) {
                    this.clearStudentActivity();
                }
            }
        );
    },

    setStudentEndedSession() {
        store.dispatch(setStudentActivity(Activity.endedSession));
    },
    clearStudentActivity() {
        store.dispatch(setStudentActivity(null));
    },

    async saveWhiteboardCapture(url: string) {
        const current = currentSession();
        if (!current) return;
        await SessionNetwork.captureWhiteboard(
            current.id,
            url,
            new Date().getTime() / 1000
        );
    },
    async uploadImage(file: File, onProgress?: Function) {
        const userId = getUser()?.id || 0;
        const url = await UploadImage.send(file, userId, file.type, onProgress);

        SessionMessages.sendText(url, true);
    },

    handleEndSession() {
        store.dispatch(setWantsToEndSession(true));
    },
    handleCancelEndSession() {
        store.dispatch(setWantsToEndSession(false));
    },

    async studentEndedSession(data: SessionType) {
        await this.endSession(
            data.ended_action || EndedAction.studentEndButton,
            data.ended_at || serverTime()
        );
    },

    async endSession(reason: EndedAction, endedAt: number): Promise<Response> {
        const tutorEnded = reason === EndedAction.tutorEndButton;
        const localSession = currentSession();
        let response: Response = { success: true };
        if (!localSession) return { success: false };

        store.dispatch(setProcessingSession(true));
        if (tutorEnded) {
            response = await SessionNetwork.endSession(
                localSession.id,
                endedAt,
                reason
            );
        }
        if (response.success) {
            await this.sync();
            const current = currentSession();

            if (tutorEnded && !current?.needs_feedback) {
                Logger.event(LoggerEvent.sessionCleared, "on_end_session", {
                    local: {
                        session_id: localSession.id,
                        ended_action: localSession.ended_action
                    },
                    current: {
                        session_id: current?.id,
                        ended_action: current?.ended_action
                    },
                    student_msg_count: current?.messages.filter(
                        (msg) => msg.sent_from === MessageSender.student
                    ).length
                });
                if (localSession?.id !== current?.id) {
                    Sentry.logError(
                        `Ending session with different id: ${localSession?.id} != ${current?.id}`
                    );
                }
                this.clear();
            } else if (current) {
                store.dispatch(
                    setCurrent({
                        ...current,
                        ended_at: endedAt,
                        ended_action: reason
                    })
                );
            }
        } else {
            store.dispatch(setError(response.message ?? ""));
        }
        store.dispatch(setWantsToEndSession(false));
        store.dispatch(setProcessingSession(false));
        return response;
    },

    async submitFeedback(
        feedback: SubmitPostSessionFeedbackRequest
    ): Promise<void> {
        const localSession = currentSession();
        if (!localSession) return;
        const response = await SessionNetwork.submitFeedback(
            localSession.id,
            feedback
        );
        const current = currentSession();
        if (response.success) {
            Logger.event(LoggerEvent.sessionCleared, "on_submit_feedback", {
                local: {
                    session_id: localSession.id,
                    ended_action: localSession.ended_action
                },
                current: {
                    session_id: current?.id,
                    ended_action: current?.ended_action
                }
            });
            if (localSession?.id !== current?.id) {
                Sentry.logError(
                    `Ending session with different id: ${localSession?.id} != ${current?.id}`
                );
            }
            this.clear();
        } else {
            store.dispatch(setError(response.message ?? ""));
        }
    },

    setSyncSessionInterval(delay: number): void {
        if (
            this.currentInterval === delay &&
            Timers.hasTimer(sessionSyncTimerLabel)
        )
            return;
        // the timers library will clear an existing interval if it's already set
        Timers.setInterval({
            label: sessionSyncTimerLabel,
            callback: () => this.sync(),
            delay
        });
        this.currentInterval = delay;
    },

    debugSessionEndRedirect(response: any) {
        const current = currentSession();
        if (current && current.ended_at && !current.needs_feedback) {
            Logger.event(LoggerEvent.debugSessionEndRedirect, "", {
                session_id: current?.id,
                msg_count: current?.messages.filter(
                    (msg) => msg.sent_from === MessageSender.student
                ).length,
                response_needs_feedback: response.data.needs_feedback
            });
        }
    },

    async sync() {
        const current = currentSession();
        if (!current || this.isSyncing) return;
        this.isSyncing = true;
        const response = await SessionNetwork.get(current.id);
        if (!response.success) {
            this.setSyncSessionInterval(Intervals.disconnectedSyncInterval);
            this.isSyncing = false;
            return;
        }

        // Make sure poll doesn't continue if session has ended
        if (!isCompleted(response.data) && !isGivingFeedback(response.data)) {
            this.setSyncSessionInterval(Intervals.connectedSyncInterval);
        }

        if (current.id !== response.data.id) {
            store.dispatch(setMessages(response.data.messages));
            store.dispatch(setCurrent(response.data));
            this.isSyncing = false;
            return;
        }

        store.dispatch(setCurrent(response.data));

        if (isEnded(currentSession())) {
            await this.studentEndedSession(currentSession()!);
        }

        this.debugSessionEndRedirect(response);

        this.isSyncing = false;
    },

    clearError() {
        store.dispatch(setError(""));
    },

    clear() {
        store.dispatch(setCurrent(null));
    }
};

export default Session;

export function currentSession(): SessionType | null {
    return store.getState().session.current || null;
}

export function getSessionLogInfo() {
    const session = currentSession();
    return {
        math_crunch_session_id: session?.id
    };
}

export function getSessionInfo() {
    const current = currentSession();
    return {
        sessionId: current?.id.toString() ?? "",
        messageKeys: JSON.stringify(current?.messages.map((m) => m.key) ?? []),
        sessionChannel: current?.channel_name
    };
}

export class PusherEvent {
    static channel: PusherChannel | null = null;
    static async on(eventName: string, callback: Function) {
        if (!this.channel) {
            const { sessionChannel } = getSessionInfo();
            this.channel = await pusherHelper.subscribeToChannel(
                sessionChannel!
            );
        }
        this.channel?.bind(eventName, callback);
    }
    static remove(eventName: string, callback: Function) {
        this.channel?.unbind(eventName, callback);
    }
}

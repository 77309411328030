import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { Metrics, SessionPage } from "shared/types/Workbook";
import store from "store";
import { StoreKey } from "store/StoreKey";

const storePrefix = StoreKey.workbook;
const storage = new Storage(storePrefix, clearWorkbook);
function clearWorkbook(): void {
    store.dispatch(workbookSlice.actions.reset());
}

type WorkbookStateType = {
    metrics: Metrics | null;
    page: SessionPage | null;
    loading: boolean;
    loadingSessions: boolean;
    perPage: number | null;
    error: string;
    favoritedBy: Array<number>;
};

const workbookSlice = createSlice({
    name: "workbook",
    initialState: {
        page: null,
        stats: null,
        loading: true,
        loadingSessions: true,
        perPage: null,
        error: "",
        favoritedBy: [],
        metrics: null
    } as WorkbookStateType,
    reducers: {
        setPerPage: (state, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLoadingSessions: (state, action: PayloadAction<boolean>) => {
            state.loadingSessions = action.payload;
        },
        setMetrics: (state, action: PayloadAction<Metrics | null>) => {
            state.metrics = action.payload;
            updateCache(state);
        },
        setPage: (state, action: PayloadAction<SessionPage | null>) => {
            state.page = action.payload;
            updateCache(state);
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        setFavoritedBy: (state, action: PayloadAction<Array<number>>) => {
            state.favoritedBy = action.payload;
        },
        reset: (state) => {
            state.metrics = null;
            state.page = null;
            updateCache(state);
        },
        init: (state) => {
            state.metrics = storage.get()?.metrics;
            state.page = storage.get()?.page;
        }
    }
});

export default workbookSlice.reducer;

export const {
    setMetrics,
    setPage,
    setPerPage,
    setLoading,
    setLoadingSessions,
    setError,
    setFavoritedBy,
    init
} = workbookSlice.actions;

function updateCache(state: WorkbookStateType) {
    if (state.metrics ?? state.page) {
        storage.set(state);
    } else {
        storage.remove();
    }
}

export const selectFavoritedBy = (state: {
    workbook: WorkbookStateType;
}): Array<number> => state.workbook.favoritedBy;

import { LocalStorageHelper } from "@yups/utils";
import WebService from "helpers/WebService";

const STORE_PREFIX = "server-sync-offset";
const maxRetries = 3;
let retryCount = 0;

export async function syncTime(): Promise<void> {
    const startTime = new Date().getTime() / 1000;

    const response = await WebService.serverTime();
    if (!response.success) {
        retrySyncTime();
        return;
    }

    const endTime = new Date().getTime() / 1000;
    const diff = endTime - startTime;
    if (diff < 1) {
        const serverSyncOffset = response.data.time_stamp - endTime - diff / 2;
        LocalStorageHelper.setSync(STORE_PREFIX, serverSyncOffset.toString());
    } else {
        retrySyncTime();
    }
}

export function serverTime(): number {
    const serverSyncOffset = LocalStorageHelper.getSync(STORE_PREFIX);
    const clientTimestamp = new Date().getTime() / 1000;
    return serverSyncOffset
        ? clientTimestamp + Number(serverSyncOffset)
        : clientTimestamp;
}

function retrySyncTime(): void {
    retryCount += 1;
    if (retryCount < maxRetries) {
        setTimeout(() => {
            syncTime();
        }, Math.pow(retryCount, 2) * 1000);
    }
}

import React from "react";
import ReactDOM from "react-dom";

import { AuthHelper, EnvironmentHelper } from "@yups/utils";
import { StorageManager } from "helpers/Storage";
import { Loading } from "flows/Loading";
import { initStore } from "store";
import { User } from "models/User";

ReactDOM.render(<Loading />, document.getElementById("root"));

(async () => {
    await EnvironmentHelper.init();
    const isAuthenticated = await AuthHelper.isAuthenticated();
    if (!isAuthenticated) {
        await StorageManager.clearCache();
    } else {
        await StorageManager.restoreCache();
        initStore();
        User.fetch();
    }
    const { Initialize } = await import("./Initializer");
    await Initialize(isAuthenticated);
    const { AppController } = await import("controllers/AppController");
    AppController.init();
    AppController.render();
})();

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const highlight: BaseCommand = {
    execute(
        context: ZwibblerContext,
        props: { lineWidth: number; opacity: number }
    ) {
        context.useBrushTool({
            strokeStyle: "rgba(255,255,0,0.5)",
            lineWidth: props.lineWidth,
            opacity: props.opacity
        });
    }
};

export default highlight;

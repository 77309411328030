import { OnboardingStatus } from "./Onboarding";

export enum UserRole {
    seniorTutor = "senior_tutor",
    internalTQM = "internal_tqm",
    admin = "admin",
    tutor = "tutor",
    tutorApplicant = "tutor_applicant"
}

export enum ExamStatus {
    failed = "failed_exam",
    passed = "passed_exam"
}

export type SubjectStatus = {
    can_start_exam: boolean;
    subject_id: number;
    status?: string;
    retake_exam_on?: string;
};

export interface UserType {
    id: number;
    roles: Array<UserRole>;
    email: string;
    identifier: string;
    access_token: string;
    token: string;
    name: string;
    profile: {
        id: number;
        full_name: string;
        assigned_sessions_count: number;
        rating?: number;
        number_of_sessions?: number;
        name: string;
    };
    application: {
        onboarding_status: {
            label: string;
            name: OnboardingStatus;
        };
        unique_token: string;
    };
    settings: {
        credentials: {
            pusher: {
                auth_endpoint: string;
                key: string;
            };
        };
    };
    updates_channel: string;
    subject_statuses: Array<SubjectStatus>;
    candidate_id?: string;
}

import store from "store/index";
import { pusherHelper } from "@yups/utils";
import { setPusherError, setConnectionSpotty, setDisconnected } from "store/UI";

const Messages = {
    pusherDisconnected: "You may not receive matches or real-time messages",
    pusherError:
        "You may not receive matches or real-time messages. Please refresh now"
};

export class Connection {
    static handleOnline() {
        store.dispatch(setDisconnected(false));
        store.dispatch(setConnectionSpotty(false));
    }

    static handleOffline() {
        store.dispatch(setDisconnected(true));
    }

    static handleSlowConnection() {
        store.dispatch(setConnectionSpotty(true));
    }

    /* Pusher connection */

    static startListeners() {
        if (!pusherHelper.pusher) {
            handlePusherError();
            return;
        }
        pusherHelper.onConnected(handlePusherConnect);
        pusherHelper.onDisconnected(handlePusherDisconnect);
        pusherHelper.onError(handlePusherError);
    }

    static stopListeners() {
        pusherHelper.unbind(undefined, handlePusherConnect);
        pusherHelper.unbind(undefined, handlePusherDisconnect);
        pusherHelper.unbind(undefined, handlePusherError);
    }
}

function handlePusherConnect() {
    store.dispatch(setPusherError(""));
}

function handlePusherError() {
    store.dispatch(setPusherError(Messages.pusherError));
}

function handlePusherDisconnect() {
    store.dispatch(setPusherError(Messages.pusherDisconnected));
}

import { Subtopic } from "shared/types/Subtopic";
import WebService from "helpers/WebService";
import store from "store/index";
import {
    setQuickResponses,
    setLibraryCards,
    setAchievements,
    setSubtopics,
    setUpdatesChannel,
    setCredentials
} from "store/Settings";
import FeatureDecision from "./FeatureDecision";

interface PusherCredentials {
    auth_endpoint: string;
    key: string;
}

export interface Credentials {
    pusher: PusherCredentials;
}

export interface Settings {
    credentials: Credentials | {};
    updates_channel: string;
    subtopics: Array<Subtopic>;
}

async function loadAchievements() {
    const response = await WebService.getAchievements();
    if (response.success) {
        store.dispatch(
            setAchievements(
                [...response.data].filter(
                    (achievement) =>
                        achievement.achievement_type !== "LOCKED_ACHIEVEMENT"
                )
            )
        );
    }
}

async function loadLibraryCards() {
    const response = await WebService.getLibraryCards();
    if (response.success) {
        const cards = FeatureDecision.enableYupAPI("getLibraryCards")
            ? response.data.cards
            : response.data.library_cards.cards;

        store.dispatch(
            setLibraryCards(
                [...cards].sort((a: any, b: any) =>
                    a.term.toLowerCase() < b.term.toLowerCase() ? -1 : 1
                )
            )
        );
    }
}

async function loadQuickResponses() {
    const response = await WebService.getQuickResponses();
    if (response.success) {
        store.dispatch(
            setQuickResponses(
                [...response.data].sort((a, b) =>
                    a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
                )
            )
        );
    }
}

export async function loadSessionSettings(): Promise<void> {
    await Promise.all([
        loadAchievements(),
        loadLibraryCards(),
        loadQuickResponses()
    ]);
}

export function getSettings(): Settings | null {
    return store.getState().settings;
}

export function setSettings(data: any): void {
    store.dispatch(setSubtopics(data.subtopics));
    store.dispatch(setUpdatesChannel(data.updates_channel));
    store.dispatch(setCredentials(data.credentials));
}

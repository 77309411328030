import { EventEmitter } from "events";
import Debug, { DebugLog } from "helpers/Debug";
import { DebugLogger } from "@yups/utils";
import { UserEvent } from "./UserEvent";
import { SystemEvent } from "./SystemEvent";
import { EventLog } from "./EventLog";

export type EventType = UserEvent & SystemEvent;

const eventEmitter = new EventEmitter();
const debugLogger = new DebugLogger("EVENT");

class EventClass<T> {
    on<K extends keyof T>(
        eventName: K,
        callback: (payload: T[K]) => void
    ): void {
        eventEmitter.addListener(eventName as string, callback);
    }
    dispatch<K extends keyof T>(eventName: K, data?: T[K]) {
        const timestamp = Date.now();
        const eventData = toJson(data ?? {});
        const name = eventName as keyof EventType;

        this.logDebug<K>(eventName, timestamp, eventData);

        EventLog.add(name, timestamp, eventData);

        eventEmitter.emit(eventName as string, eventData);
    }

    remove<K extends keyof T>(eventName: K, callback: (payload: T[K]) => void) {
        eventEmitter.removeListener(eventName as string, callback);
    }
    dispatcher<K extends keyof T>(eventName: K, data?: T[K]) {
        return (event?: React.SyntheticEvent<HTMLElement>) =>
            this.dispatch(eventName, data);
        // TODO: Track the HTML elements the user interacted with
        // using "data-" attributes and `event?.currentTarget.dataset`
    }

    private logDebug<K extends keyof T>(
        eventName: K,
        timestamp: number,
        eventData: any
    ) {
        if (!Debug.get(DebugLog.Observers)) return;
        const css = ["color: orange", "font-weight: bold"];
        debugLogger.info(
            `%c${eventName}`,
            css.join(";"),
            `at ${timestamp}:`,
            eventData ?? "no data"
        );

        if (!eventEmitter.listenerCount(eventName.toString()))
            console.warn(`${eventName} has no listeners.`);
    }
}

const toJson = (data: any): JSON => JSON.parse(JSON.stringify(data));

export const Event = new EventClass<EventType>();

export const StoreKey: { [key: string]: string } = {
    user: "yup-user-data",
    shift: "yup-shift",
    session: "yup-session",
    settings: "yup-settings",
    sessionReview: "yup-session-review",
    workbook: "yup-tutor-workbook",
    gradingTimeTracker: "yup-grading-time-tracker",
    networkHealthMonitor: "yup-network-health-monitor",
    device: "yup-device"
};

import { RootStateOrAny } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import store from "store";
import { Page } from "shared/components/Whiteboard/types/Page";

export const storePrefix = "yup-whiteboard";
export const storage = new Storage(storePrefix, clearWhiteboard);
function clearWhiteboard(): void {
    store.dispatch(whiteboardSlice.actions.reset());
}

export enum ConnectionState {
    notConnected = "not connected",
    connected = "connected",
    inError = "reconnecting...",
    connectionNotPossible = "connection not possible"
}

type WhiteboardStateType = {
    connectionState: ConnectionState;
    pages: Array<Page>;
    currentPage: number;
    showPages: boolean;
};

export const whiteboardSlice = createSlice({
    name: "whiteboard",
    initialState: {
        connectionState: ConnectionState.notConnected,
        pages: [],
        showPages: false,
        currentPage: 0
    } as WhiteboardStateType,
    reducers: {
        setConnectionState: (state, action: PayloadAction<ConnectionState>) => {
            state.connectionState = action.payload;
        },
        setPages: (state, action: PayloadAction<Array<Page>>) => {
            state.pages = [...action.payload];
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setShowPages: (state, action: PayloadAction<boolean>) => {
            state.showPages = action.payload;
        },
        reset: (state) => {
            state.connectionState = ConnectionState.notConnected;
        }
    }
});

export default whiteboardSlice.reducer;

export const { setConnectionState, setPages, setCurrentPage, setShowPages } =
    whiteboardSlice.actions;

export const selectConnected = (state: RootStateOrAny): boolean =>
    state.whiteboard.connectionState === ConnectionState.connected;
export const selectConnectionNotPossible = (state: RootStateOrAny): boolean =>
    state.whiteboard.connectionState === ConnectionState.connectionNotPossible;
export const selectPages = (state: RootStateOrAny): Array<Page> =>
    state.whiteboard.pages;
export const selectCurrentPage = (state: RootStateOrAny): number =>
    state.whiteboard.currentPage;
export const selectCurrentPageLive = (state: RootStateOrAny): boolean =>
    state.whiteboard.currentPage === 0;

export const isCurrentPageLive = (): boolean => {
    const state = store.getState();
    return selectConnected(state) && selectCurrentPageLive(state);
};

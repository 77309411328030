import { MessageType } from "shared/components/ChatRoom/types/Message";
import { CoveredTopic } from "./SessionFeedback";
import { EndedAction } from "shared/types/EndedAction";
import { Tutor } from "./Tutor";
import {
    EndSessionRequest,
    SubmitPostSessionFeedbackRequest,
    TutorReadyRequest
} from "helpers/YupAPI";

enum State {
    assigning = 0,
    reviewing,
    started,
    ended,
    completed
}
const stateOf = (session: Session): State => {
    if (session.ended_at && !session.needs_feedback) return State.completed;
    if (session.ended_at) return State.ended;
    if (session.started_at || session.tutor_ready_at) return State.started;
    if (session.claimed_at) return State.reviewing;
    return State.assigning;
};

export const isAssigning = (session: Session | null) =>
    !!session && stateOf(session) === State.assigning;
export const isReviewing = (session: Session | null) =>
    !!session && stateOf(session) === State.reviewing;
export const isTutoring = (session: Session | null) =>
    !!session && stateOf(session) === State.started;
export const isGivingFeedback = (session: Session | null) =>
    !!session && stateOf(session) === State.ended;

export const isAssigned = (session: Session | null) =>
    !!session && stateOf(session) > State.assigning;
export const isReviewed = (session: Session | null) =>
    !!session && stateOf(session) > State.reviewing;
export const isEnded = (session: Session | null) =>
    !!session && stateOf(session) > State.started;
export const isCompleted = (session: Session | null) =>
    !!session && stateOf(session) > State.ended;

type ResponseTime = {
    delta_t: number;
};

type SessionReview = {
    id: number;
};

export type RecentSession = {
    id: number;
    created_at: number;
    length: number;
    student_rating?: number;
    tutor_comment?: string;
    student_comment?: string;
};

type LastSession = {
    created_at: number;
    public_url: string;
};

export type Session = {
    app_version: string;
    channel_name: string;
    claimed_at: number;
    created_at: number;
    ended_at: number;
    ended_action: EndedAction;
    feedback?: SessionReview;
    grade_level?: number;
    id: number;
    is_new_user: boolean;
    messages: Array<MessageType>;
    needs_feedback: boolean;
    number: number;
    platform: string;
    started_at: number;
    student_feedback: string;
    student_first_name: string;
    student_rating: number;
    student_standard_comments: Array<string>;
    student_subtopic_id: number;
    topic: string;
    tutor_comment_for_student: string;
    tutor_ready_at: number;
    tutor_response_times: Array<ResponseTime>;
    tutor: Tutor;
    user_id: number;
    username: string;
    last_session_with_tutor?: LastSession;
    recent_sessions: Array<RecentSession>;
    covered_topics?: Array<CoveredTopic>;
};

export type SessionUpdateTypes =
    | EndSessionRequest
    | SubmitPostSessionFeedbackRequest
    | TutorReadyRequest;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const snap: BaseCommand = {
    execute(context: ZwibblerContext, snap: boolean) {
        context.setConfig("snap", snap ? 20 : 0);
        LogAction(context, `background_snap_to_grid`);
    }
};

export default snap;

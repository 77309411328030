import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const arrow: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.useArrowTool({
            arrowStyle: "solid",
            doubleArrow: false
        });
    }
};

export default arrow;

import * as SentryIntegration from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Config } from "./Config";
import { UserType } from "shared/types/User";

export const Sentry = {
    initialize(user: UserType | null) {
        SentryIntegration.init({
            dsn: Config.sentryUrl,
            integrations: [new Integrations.BrowserTracing()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0
        });

        if (user) {
            this.setUser(user.id);
        }
    },
    setUser(userId: number) {
        SentryIntegration.configureScope((scope) =>
            scope.setUser({ id: userId.toString() })
        );
    },
    clearUser() {
        SentryIntegration.configureScope((scope) => scope.setUser(null));
    },
    logError(error: any) {
        SentryIntegration.captureException(error);
    }
};

export default Sentry;

export function setUser(userId: number) {
    Sentry.setUser(userId);
}

export function clearUser() {
    Sentry.clearUser();
}

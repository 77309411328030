import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

const WhiteboardConfig = {
    maxImageSize: 1080
};

export const send: BaseCommand = {
    async execute(context: ZwibblerContext, callback: (file: File) => void) {
        const rect = context.getViewRectangle();

        // Create the image
        const image = context.save("png", rect, WhiteboardConfig.maxImageSize);
        const result = await fetch(image);
        const blob = await result.blob();
        const file = new File([blob], "File name", { type: "image/png" });
        callback(file);
        LogAction(context, WhiteboardAction.send);
    }
};

export default send;

import WebService from "helpers/WebService";
import Shift, { currentShift } from "./Shift";
import { setUserSession } from "helpers/UserSession";
import { setSettings, loadSessionSettings } from "models/Settings";
import WebPushManager from "helpers/WebPush";
import { setUser as setSentryUser } from "helpers/Sentry";
import { cleanup } from "helpers/Cleanup";
import { Pusher } from "helpers/Pusher";

import { UserType, UserRole } from "shared/types/User";

import store, { initStore } from "store/index";
import { setUser, setError } from "store/User";
import { setReferralSources, setSupportEmail } from "store/Onboarding";
import {
    setProcessingSignIn,
    setForgotPasswordSuccess,
    setResetPasswordSuccess
} from "store/UI";
import { OnboardingStatus } from "shared/types/Onboarding";
import Onboarding from "./Onboarding";
import { Event } from "events/Event";

enum ErrorMessages {
    errorSignIn = "Unable to sign in. Please try again.",
    errorForgotPassword = "Unable to send password reset link. Please try again.",
    errorResetPassword = "Unable to reset password.",
    errorResetPasswordTokenExpired = "Reset link has already been used or has expired.",
    errorResetPasswordMismatch = "Passwords don't match.",
    errorSignUpEmailMismatch = "Emails don't match.",
    errorSignUpEmailExists = "An account with this email already exists.",
    errorDefault = "Something went wrong. Please try again."
}

export const User = {
    isApplicant() {
        const user = getUser();
        return !!user && !user.roles.includes(UserRole.tutor);
    },

    isAdmin() {
        const user = getUser();
        return !!user && user?.roles.includes(UserRole.admin);
    },

    clearError() {
        store.dispatch(setError(""));
    },

    set(user: UserType | null) {
        store.dispatch(setUser(user));
        if (user) {
            setSentryUser(user.id);

            const settings = {
                ...user.settings,
                updates_channel: user.updates_channel
            };
            setSettings(settings);
        }
    },

    async fetch() {
        const response = await WebService.getUser();
        if (response.success) {
            this.set(response.data);
        }
    },

    async checkForShift() {
        if (currentShift()) return;

        const response = await WebService.getCurrentShift();
        if (response.success && response.data?.shift) {
            await Shift.load(response.data.shift);
        }
    },

    async logIn(email: string, password: string): Promise<void> {
        store.dispatch(setProcessingSignIn(true));
        try {
            WebService.setRetries(2);
            const response = await WebService.login({
                include_settings: true,
                user: { email, password }
            });
            if (!response.success) {
                store.dispatch(
                    setError(response.message ?? ErrorMessages.errorSignIn)
                );
                throw new Error(response.message);
            }
            const { data } = response;
            this.set(data);

            WebPushManager.getSubscription(); // TODO: await + error handling
            setUserSession({
                identifier: data.identifier,
                access_token: data.access_token,
                settings: data.settings
            });
            store.dispatch(setUser(data));
            setSentryUser(data.id);

            const settings = {
                ...data.settings,
                updates_channel: data.updates_channel
            };
            setSettings(settings);

            await Promise.all([
                loadSessionSettings(),
                Pusher.initialize(),
                this.checkForShift()
            ]);
        } catch (error) {
            console.error(error);
        }
        store.dispatch(setProcessingSignIn(false));
    },

    logOut() {
        store.dispatch(setUser(null));
        cleanup();
        initStore();
    },

    async submitForgotPassword(email: string): Promise<void> {
        store.dispatch(setProcessingSignIn(true));
        store.dispatch(setForgotPasswordSuccess(false));
        const response = await WebService.forgotPassword({
            include_settings: true,
            email
        });
        store.dispatch(setForgotPasswordSuccess(response.success));
        if (!response.success) {
            store.dispatch(setError(ErrorMessages.errorForgotPassword));
        }
        store.dispatch(setProcessingSignIn(false));
    },

    async submitResetPassword(
        password: string,
        confirmPassword: string
    ): Promise<void> {
        if (password !== confirmPassword) {
            store.dispatch(setError(ErrorMessages.errorResetPasswordMismatch));
            return;
        }

        store.dispatch(setProcessingSignIn(true));
        store.dispatch(setResetPasswordSuccess(false));
        const urlParams = new URLSearchParams(window.location.search);
        const response = await WebService.resetPassword({
            password,
            reset_token: urlParams.get("reset_password_token") || ""
        });
        store.dispatch(setResetPasswordSuccess(response.success));
        if (response.data.errors?.invalid_token) {
            store.dispatch(
                setError(ErrorMessages.errorResetPasswordTokenExpired)
            );
        } else if (!response.success) {
            store.dispatch(setError(ErrorMessages.errorResetPassword));
        }
        store.dispatch(setProcessingSignIn(false));
    },

    async loadPreOnboardingData() {
        const response = await WebService.getPreOnboardingData();
        if (!response.success) {
            store.dispatch(setError(ErrorMessages.errorDefault));
        } else {
            store.dispatch(setReferralSources(response.data.referral_sources));
            store.dispatch(setSupportEmail(response.data.tutor_support_email));
        }
    },
    async checkEmail(email: string, confirmEmail: string): Promise<boolean> {
        if (email !== confirmEmail) {
            store.dispatch(setError(ErrorMessages.errorSignUpEmailMismatch));
            return false;
        }
        store.dispatch(setProcessingSignIn(true));
        const response = await WebService.validateOnboardingData(email);
        if (response.data.email_exists) {
            store.dispatch(setError(ErrorMessages.errorSignUpEmailExists));
        } else if (!response.success) {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
        store.dispatch(setProcessingSignIn(false));
        return !response.data.email_exists && response.success;
    },
    checkPassword(password: string, confirmPassword: string): boolean {
        if (password !== confirmPassword) {
            store.dispatch(setError(ErrorMessages.errorResetPasswordMismatch));
            return false;
        }
        return true;
    },
    async signUp(form: {
        first_name: string;
        last_name: string;
        phone_number: string;
        referral_source: string;
        email: string;
        password: string;
    }) {
        store.dispatch(setProcessingSignIn(true));
        const response = await WebService.submitOnboarding({
            ...form,
            date_of_birth: ""
        });
        if (!response.success) {
            store.dispatch(setError(ErrorMessages.errorDefault));
        } else {
            await this.logIn(form.email, form.password);
            await Onboarding.updateStatus(OnboardingStatus.background);
        }
        store.dispatch(setProcessingSignIn(false));
        return response.success;
    }
};

export function getUser(): UserType | null {
    return store.getState().user.user ?? null;
}

export function getUserLogInfo() {
    const user = getUser();
    return {
        tutor_profile_id: user?.profile.id,
        user_id: user?.id
    };
}

export function getUserInfo() {
    const user = getUser();
    return {
        userId: user?.id.toString() ?? "",
        email: user?.email ?? ""
    };
}

export async function getUserAdminDetails(userToken: string) {
    if (!getUser()?.roles.includes(UserRole.admin)) {
        return { success: false };
    }
    return await WebService.getUserAdminDetails(userToken);
}

export function onUnauthorized() {
    Event.dispatch("shift_unauthorized");
}

export enum MatchSource {
    WebSockets = "websockets",
    HTTP = "http"
}

export enum MatchReason {
    ok = "ok",
    shift_ended = "shift_ended",
    cancelled = "user_canceled",
    reassigned = "reassigned",
    error = "error"
}

export type Match = {
    matchSource: MatchSource;
    matchmakingId: number;
    topic: string;
};

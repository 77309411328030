import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    OnboardingBackgroundData,
    OnboardingSettings,
    DefaultOnboardingSettings,
    OnboardingExam,
    OnboardingExamQuestion,
    OnboardingStatus,
    OnboardingStatusInfo,
    OnboardingExamReview,
    OnboardingBackgroundCheckStatus
} from "shared/types/Onboarding";

type OnboardingStateType = {
    referralSources: Array<string>;
    supportEmail: string;
    settings: OnboardingSettings;
    backgroundInfo: OnboardingBackgroundData;
    exam: OnboardingExam | null;
    examQuestions: Array<OnboardingExamQuestion>;
    backgroundCheckStatus: OnboardingBackgroundCheckStatus | null;
    examReview: OnboardingExamReview | null;
    contract: string;
    contractReview: string;
    error: string;
};

function getGroup(status: OnboardingStatusInfo) {
    switch (status.name) {
        case OnboardingStatus.waiting_for_offer:
        case OnboardingStatus.failed_teaching_exam:
            return "step_2";
        case OnboardingStatus.waiting_for_schedule:
            return "step_4";
        default:
            return status.group;
    }
}

const onboardingSlice = createSlice({
    name: "onboarding",
    initialState: {
        referralSources: [],
        supportEmail: "",
        settings: DefaultOnboardingSettings,
        backgroundInfo: {
            degrees: [],
            interests: [],
            schools: [],
            subjects: [],
            years: []
        },
        exam: null,
        examQuestions: [],
        examReview: null,
        backgroundCheckStatus: null,
        contractReview: "",
        contract: "",
        error: ""
    } as OnboardingStateType,
    reducers: {
        setReferralSources: (state, action: PayloadAction<Array<string>>) => {
            state.referralSources = action.payload;
        },
        setSupportEmail: (state, action: PayloadAction<string>) => {
            state.supportEmail = action.payload;
        },
        setBackgroundInfo: (
            state,
            action: PayloadAction<OnboardingBackgroundData>
        ) => {
            state.backgroundInfo = action.payload;
        },
        setSettings: (state, action: PayloadAction<OnboardingSettings>) => {
            state.settings = action.payload;
            state.settings.onboarding_statuses =
                action.payload.onboarding_statuses.map((status) => ({
                    ...status,
                    group: getGroup(status)
                }));
        },
        setExam: (state, action: PayloadAction<OnboardingExam>) => {
            state.exam = action.payload;
        },
        setExamQuestions: (
            state,
            action: PayloadAction<Array<OnboardingExamQuestion>>
        ) => {
            state.examQuestions = action.payload;
        },
        setExamReview: (state, action: PayloadAction<OnboardingExamReview>) => {
            state.examReview = action.payload;
        },
        setBackgroundCheckStatus: (
            state,
            action: PayloadAction<OnboardingBackgroundCheckStatus>
        ) => {
            state.backgroundCheckStatus = action.payload;
        },
        setContract: (state, action: PayloadAction<string>) => {
            state.contract = action.payload;
        },
        setContractReview: (state, action: PayloadAction<string>) => {
            state.contractReview = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        }
    }
});

export default onboardingSlice.reducer;

export const {
    setReferralSources,
    setSupportEmail,
    setBackgroundInfo,
    setSettings,
    setExam,
    setExamQuestions,
    setExamReview,
    setBackgroundCheckStatus,
    setContract,
    setContractReview,
    setError
} = onboardingSlice.actions;

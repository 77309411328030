import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { Achievement } from "shared/types/Achievement";
import { LibraryCard } from "shared/types/LibraryCard";
import { Subtopic } from "shared/types/Subtopic";
import { QuickResponse } from "shared/types/QuickResponse";
import { Credentials } from "models/Settings";
import { StoreKey } from "store/StoreKey";

const storePrefix = StoreKey.settings;
const storage = new Storage(storePrefix, clearSettings);

type SettingsStateType = {
    quick_responses: Array<QuickResponse>;
    library_cards: Array<LibraryCard>;
    achievements: Array<Achievement>;
    subtopics: Array<Subtopic>;
    updates_channel: string;
    credentials: Credentials | {};
};

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        quick_responses: [],
        library_cards: [],
        achievements: [],
        subtopics: [],
        updates_channel: "",
        credentials: {}
    } as SettingsStateType,
    reducers: {
        setQuickResponses: (
            state,
            action: PayloadAction<Array<QuickResponse>>
        ) => {
            state.quick_responses = action.payload;
            updateCache(state);
        },
        setLibraryCards: (state, action: PayloadAction<Array<LibraryCard>>) => {
            state.library_cards = action.payload;
            updateCache(state);
        },
        setAchievements: (state, action: PayloadAction<Array<Achievement>>) => {
            state.achievements = action.payload;
            updateCache(state);
        },
        setSubtopics: (state, action: PayloadAction<Array<Subtopic>>) => {
            state.subtopics = action.payload;
            updateCache(state);
        },
        setUpdatesChannel: (state, action: PayloadAction<string>) => {
            state.updates_channel = action.payload;
            updateCache(state);
        },
        setCredentials: (state, action: PayloadAction<Credentials>) => {
            state.credentials = action.payload;
            updateCache(state);
        },
        init: (state) => {
            const cached = storage.get() ?? {};
            state.quick_responses = cached.quick_responses ?? [];
            state.library_cards = cached.library_cards ?? [];
            state.achievements = cached.achievements ?? [];
            state.subtopics = cached.subtopics ?? [];
            state.updates_channel = cached.updates_channel ?? "";
            state.credentials = cached.credentials ?? {};
        }
    }
});

export default settingsSlice.reducer;

export const {
    setQuickResponses,
    setLibraryCards,
    setAchievements,
    setSubtopics,
    setUpdatesChannel,
    setCredentials,
    init
} = settingsSlice.actions;

function updateCache(state: SettingsStateType) {
    storage.set(state);
}

function clearSettings(): void {
    storage.remove();
}

export enum EndedAction {
    serverTimedOut = "server:timed_out",
    serverCleanup = "server:cleanup",
    systemStudentInactive = "system:student_inactive",
    systemStudentNotPreset = "system:student_not_present",
    systemNoStudentMessage = "system:no_student_message",
    tutorEndButton = "tutor:end_button",
    tutorAutomaticallyEnded = "tutor:automatically_ended",
    tutorUnableToConnect = "tutor:unable_to_connect",
    studentEndButton = "student:end_button",
    studentCancelButton = "student:cancel_button"
}

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const pencil: BaseCommand = {
    async execute(context: ZwibblerContext, lineWidth: number) {
        context.useBrushTool({
            strokeStyle: "black",
            lineWidth,
            opacity: 1
        });
    }
};

export default pencil;

import WebService, { EndShiftRequest } from "helpers/WebService";
import { pusherHelper } from "@yups/utils";
import { Response } from "shared/types/Response";
import { MatchSource, MatchReason } from "shared/types/Match";
import { getSettings } from "models/Settings";
import { getUser } from "models/User";
import Logger, { Event } from "helpers/Logger";
import Sentry from "helpers/Sentry";
import { PusherChannel } from "@yups/utils";
import { Timers } from "@yups/utils";
import Session, { currentSession } from "models/Session";
import { isCompleted, Session as SessionType } from "shared/types/Session";
import Shift from "models/Shift";

enum ErrorMessages {
    errorMissingMatchCallback = "Match callback isn't set. Assignment via Pusher was dropped.",
    errorStartShift = "Unable to start a shift. Please try again in a few seconds.",
    errorEndShift = "Unable to end your shift. Please try again in a few seconds.",
    errorClaimTimeout = "Claim timeout request failed.",
    errorClaimMatchReassigned = "This session has been reassigned.",
    errorClaimMatchCancelled = "This session has been cancelled by the student.",
    errorClaimMatchShiftEnded = "Unable to claim match as you have ended your shift.",
    errorClaimMatchError = "This session cannot be claimed.",
    errorClaimMatchUnexpectedError = "Unable to claim match at this time. Please check your network connection."
}

const pusherEvents = [
    { event: "matches-assigned", callback: pusherMatchAssignment },
    { event: "backend-ping", callback: pusherBackendPingReceived }
];

export type ClaimMatchResponse = Response & {
    data?: {
        claimed: boolean;
        reason: string;
        match?: SessionType;
    };
};

export enum ShiftEvents {
    onMatchAssignment = "on-match-assignment",
    onShiftUpdate = "on-shift-update",
    onActiveSessionAssignment = "on-active-session-assignment",
    onShiftError = "on-shift-error"
}

let channel: PusherChannel | null = null;
const checkAssignedMatchLabel = "assigned-match";

const ShiftNetwork = {
    async start(): Promise<Response> {
        const response = await WebService.shiftStart();
        if (!response.success) {
            Shift.setError(ErrorMessages.errorStartShift);
        }
        return response;
    },

    async end(payload: EndShiftRequest, id?: number): Promise<Response> {
        let response = await WebService.shiftEnd(payload);
        if (id) {
            // See if shift has automatically or manually ended
            response = await WebService.shiftGet(id);
            response.success =
                response.success && Boolean(response.data?.end_at);
        }
        if (!response.success) {
            Shift.setError(ErrorMessages.errorEndShift);
        }
        return response;
    },

    async get(shiftId: number): Promise<Response> {
        return await WebService.shiftGet(shiftId);
    },

    async startMatchListeners() {
        const settings = getSettings();
        if (!settings) return;

        checkAssignedMatch();

        try {
            channel = await pusherHelper.subscribeToPublicChannel(
                settings.updates_channel
            );
            Logger.event(Event.pusherSubscribedToChannel, "", {
                channel: settings.updates_channel
            });
            pusherEvents.forEach((event) => {
                channel?.bind(event.event, event.callback);
            });
        } catch (error) {
            Logger.event(Event.pusherSubscribedToChannelFailed, "", {
                channel: settings.updates_channel
            });
            Sentry.logError(error);
        }
    },
    stopMatchListeners() {
        Timers.clear(checkAssignedMatchLabel);

        if (channel) {
            pusherHelper.unsubscribeFromChannel(channel);
            channel = null;
        }
    },

    async claimMatch(matchmakingId: number) {
        WebService.setRetries(2);
        const response = await WebService.matchClaim(matchmakingId);
        if (!response.success) {
            switch (response.data?.reason) {
                case MatchReason.cancelled:
                    Shift.setError(ErrorMessages.errorClaimMatchCancelled);
                    break;
                case MatchReason.reassigned:
                    Shift.setError(ErrorMessages.errorClaimMatchReassigned);
                    break;
                case MatchReason.shift_ended:
                    Shift.setError(ErrorMessages.errorClaimMatchShiftEnded);
                    break;
                case MatchReason.error:
                    Shift.setError(ErrorMessages.errorClaimMatchError);
                    break;
                default:
                    Shift.setError(
                        ErrorMessages.errorClaimMatchUnexpectedError
                    );
            }
        }
        return response;
    },

    async assignmentPassed(matchmakingId: number) {
        const response = await WebService.matchTimeout(matchmakingId);
        if (!response.success) {
            response.message = ErrorMessages.errorClaimTimeout;
        }
        return response;
    }
};

export default ShiftNetwork;

/*
 * Private helpers.
 * ----------------------------
 */

async function getAssignedMatch(source: MatchSource = MatchSource.HTTP) {
    const session = currentSession();
    const isInSession = session && !isCompleted(session);
    if (isInSession) return;

    const response = await WebService.getAssignedMatch();
    const { matchmaking_id, claimed, session_id } = response.data ?? {};
    if (matchmaking_id && !claimed) {
        Shift.handleMatch({
            matchmakingId: response.data.matchmaking_id,
            matchSource: source,
            topic: response.data.topic
        });

        return;
    } else if (claimed) {
        const response = await WebService.sessionGet(session_id);
        if (response.success && response.data) {
            Session.set(response.data);
            Shift.clearMatchAssignment();
        }
    }

    // TODO: Remove once matchmaking/assigned in yup-api is live
    matchFoundLegacy(response, source);
}

function matchFoundLegacy(response: Response, source: MatchSource) {
    const { match } = response.data;
    if (!match) return;

    if (match.claimed) {
        Session.set(match);
    } else {
        Shift.handleMatch({
            matchmakingId: match.match_event_id,
            matchSource: source,
            topic: match.topic
        });
    }
}

function checkAssignedMatch() {
    if (Timers.hasTimer(checkAssignedMatchLabel)) {
        return;
    }

    Timers.setRecursiveTimeout({
        label: checkAssignedMatchLabel,
        callback: getAssignedMatch,
        delay: 5000
    });

    getAssignedMatch();
}

function pusherMatchAssignment(info: any) {
    const hasMatch = !!info.matches[getUser()!.id];
    if (hasMatch) getAssignedMatch(MatchSource.WebSockets);
}

function pusherBackendPingReceived(info: {
    [key: number]: {
        ping_id: string;
        executed_at: string;
    };
}) {
    const user_id = getUser()?.id;

    if (!user_id) return;

    if (!info[user_id]) return;

    const rtt =
        (new Date().getTime() - Date.parse(info[user_id].executed_at)) / 1000.0;

    channel?.trigger("client-pong", {
        ping_id: info[user_id].ping_id,
        rtt: rtt
    });

    Logger.event("pub_sub_pong", "", {
        ping_id: info[user_id].ping_id,
        rtt: rtt,
        executed_at: new Date().toISOString()
    });
}

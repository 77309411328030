import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const text: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.clearSelection();
        context.useTextTool();
    }
};

export default text;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { WhiteboardAction } from "../types/WhiteboardAction";
import Logger, { Event } from "helpers/Logger";

export interface BaseCommand {
    execute: (context: ZwibblerContext, value?: any) => void;
}

export function LogAction(
    context: ZwibblerContext,
    toolUsed: WhiteboardAction | string,
    args?: any
) {
    Logger.event(Event.sessionWhiteboardUsed, "", {
        page_number: context.getCurrentPage(),
        tool_used: toolUsed,
        ...(args ?? {})
    });
}

type ChangeType = "added" | "selected" | "dragged" | "removed";

export function LogPageChange(pageNumber: number, changeType: ChangeType) {
    Logger.event(Event.sessionWhiteboardPageChanged, "", {
        page_number: pageNumber,
        change_type: changeType
    });
}

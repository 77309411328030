import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogPageChange } from "./BaseCommand";

export const movePage: BaseCommand = {
    execute(context: ZwibblerContext, props: { to: number; from: number }) {
        context.movePage(props.from, props.to);
        LogPageChange(props.from, "dragged");
    }
};

export default movePage;

import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const clear: BaseCommand = {
    execute(context: ZwibblerContext) {
        context.begin();
        if (
            window.confirm(
                "Are you sure you would like to clear the current page?"
            )
        ) {
            const currentPageIndex = context.getCurrentPage();
            context.deletePage(currentPageIndex);
            const newPage = context.insertPage(currentPageIndex);
            context.setPageSize(currentPageIndex, 900, 1800); // portrait
            context.setCurrentPage(newPage);
            LogAction(context, WhiteboardAction.clear);
        }
        context.commit();
    }
};

export default clear;

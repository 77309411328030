import { LineStyle } from "../types/LineStyle";

export function getLineDashStyle(style: LineStyle, width: number) {
    if (style === LineStyle.dashed) return `${width * 3},${width}`;
    else if (style === LineStyle.dotted) {
        return `${width},${width}`;
    } else {
        return "";
    }
}

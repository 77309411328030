import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const curve: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.useCurveTool();
    }
};

export default curve;

import { AuthHelper } from "@yups/utils";

export interface UserSession {
    identifier: string;
    access_token: string;
    settings: {
        credentials: {
            pusher: {
                auth_endpoint: string;
                key: string;
            };
        };
    };
}

export function setUserSession(info: UserSession): void {
    AuthHelper.setAuthParams(info);
}

export function clearUserSession(): void {
    AuthHelper.clearAuthParams();
}

export function isLoggedIn(): boolean {
    return !!AuthHelper.getAccessToken() && !!AuthHelper.getUserId();
}

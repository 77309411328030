import { serverTime } from "helpers/SyncTime";

export enum MessageSender {
    tutor = "tutor",
    student = "student",
    systemAlert = "system alert",
    systemInfo = "system info",
    bot = "bot"
}

export enum MessageReaction {
    gotIt = "got_it",
    confused = "confused"
}

export enum MessageContentType {
    text = "text",
    image = "image"
}

export type MessageAdditionalAttributes = {
    is_latex?: boolean;
    is_badge?: boolean;
    badge_type?: string;
};

export type MessageReactionAttributes = {
    emoji: string;
    name: MessageReaction;
    label: string;
};

export type MessageType = {
    key: string;
    text: string;
    content_type: MessageContentType;
    sent_from: MessageSender;
    sent_to?: MessageSender;
    sent_at: number;
    sender_id?: number;
    chat_id?: number;
    math_crunch_session_id?: number; // App only
    created_at: number;
    additional_attributes?: MessageAdditionalAttributes;
    reaction?: MessageReactionAttributes;
    image?: string;
    source?: string;
    failedToSend?: boolean;
    isSystemMessage: boolean;
    isLatex: boolean;
    isBadge: boolean;
    id?: number;
};

export enum MessageSource {
    pusher = "pusher",
    server = "server"
}

class Message {
    text: string;
    content_type: MessageContentType;
    sent_from: MessageSender;
    sent_at: number;
    created_at: number;
    additional_attributes?: MessageAdditionalAttributes;
    reaction?: MessageReactionAttributes;
    image?: string;
    source?: string;
    key: string;
    failedToSend?: boolean;
    chat_id?: number;
    id?: number;

    constructor(message: MessageType) {
        this.additional_attributes = message.additional_attributes;
        this.text = message.text;
        this.content_type = message.content_type;
        this.sent_from = message.sent_from;
        this.sent_at = message.sent_at;
        this.created_at = message.created_at;
        this.image = message.image;
        this.source = message.source;
        this.key = message.key;
        this.reaction = message.reaction;
        this.failedToSend = message.failedToSend;
        this.chat_id = message.chat_id;
        this.id = message.id;
    }

    get isSystemMessage() {
        return (
            this.sent_from === MessageSender.systemInfo ||
            this.sent_from === MessageSender.systemAlert
        );
    }

    get isLatex() {
        return (
            !!this.additional_attributes?.is_latex ||
            /<latex>.+<\/latex>/g.test(this.text)
        );
    }

    get isBadge() {
        return Boolean(this.additional_attributes?.is_badge);
    }
}

export default Message;

export function dedupeMessages(
    messages: Array<MessageType>
): Array<MessageType> {
    /**
     * Reversed to maintain sorting order and still allow updated messages
     * to overwrite existing messages
     */
    const messageKeys = new Set();
    return [...messages]
        .reverse()
        .filter((message: MessageType) => {
            if (!messageKeys.has(message.key)) {
                messageKeys.add(message.key);
                return true;
            }
            return false;
        })
        .reverse();
}

/**
 * Performs sorting and deduping, and transforms MessageType to Message objects.
 * Message objects are used by the ChatRoom component.
 */
export function transformMessages(
    messages: Array<MessageType>
): Array<Message> {
    messages = dedupeMessages(messages);
    messages = messages.sort((a: any, b: any) => {
        a = Number(a.created_at ?? a.sent_at);
        b = Number(b.created_at ?? b.sent_at);
        return a - b;
    });

    const messageObjects = [];
    for (let i = 0; i < messages.length; ++i) {
        messageObjects.push(new Message(messages[i]));
    }

    return messageObjects;
}

/**
 * Generates a message object as defined by the app server.
 * Most of these values are no longer in use so the return value isn't typed.
 */
export function createMessage(props: {
    message: string;
    isImage: boolean;
    additionalAttributes?: MessageAdditionalAttributes;
    sessionId?: number;
    senderId?: number;
    senderToken?: string;
}) {
    const sentAt = new Date().getTime() / 1000;
    const delta = Math.abs(serverTime() - sentAt);
    const isLatex = /<\/?latex>/i.test(props.message);
    return {
        additional_attributes: {
            client_timestamp: String(sentAt),
            timestamp_delta: delta,
            is_latex: isLatex,
            ...(props.additionalAttributes ?? {})
        },
        content_type: props.isImage ? "image" : "text",
        sent_at: String(sentAt),
        sender_id: props.senderId ?? 0,
        sent_to: "student",
        sent_from: "tutor",
        text: props.message,
        sender_token: props.senderToken ?? "",
        chat_id: props.sessionId ?? 0,
        chat_type: "MathCrunchSession",
        key: `${Math.random()}.${sentAt}`
    };
}

export function getSessionId(message: MessageType): number | null {
    const sessionId = message.chat_id || message.math_crunch_session_id;
    return sessionId ? Number(sessionId) : null;
}

export function successfullySentFromSender(
    message: MessageType,
    sender: MessageSender
) {
    return message.sent_from === sender && successfullySent(message);
}

export function successfullySent(message: MessageType) {
    return Boolean(message.id);
}

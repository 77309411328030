import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogPageChange } from "./BaseCommand";

export const addPage: BaseCommand = {
    execute(context: ZwibblerContext) {
        const newPageIndex = context.getCurrentPage() + 1;
        const newPage = context.insertPage(newPageIndex);
        context.setPageSize(newPageIndex, 900, 1800); // portrait
        context.setCurrentPage(newPage);
        LogPageChange(newPage, "added");
    }
};

export default addPage;

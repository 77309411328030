import ShiftNetwork from "./ShiftNetwork";
import { Response } from "shared/types/Response";
import Logger, { Log, Event as LoggerEvent } from "helpers/Logger";
import store from "store/index";
import { Match } from "shared/types/Match";
import {
    setCurrent,
    setMatchAssignment,
    setError,
    incrementPassed,
    incrementAccepted,
    incrementTimeSpentGrading as incrementTimeSpentGradingStore
} from "store/Shift";

import { setMatchSecondsLeft, setProcessingShift } from "store/UI";
import { Event } from "events/Event";

export type ShiftType = {
    id: number;
    start_at: number;
    end_at?: number;
    accepted_sessions_count: number;
    passed_sessions_count: number;
};

const Shift = {
    async load(shiftId: number): Promise<void> {
        const response = await ShiftNetwork.get(shiftId);
        if (response.success) {
            store.dispatch(setCurrent(response.data));
            Event.dispatch("shift_start");
        }
    },

    startMatchListeners(): void {
        ShiftNetwork.startMatchListeners();
    },

    stopMatchListeners(): void {
        ShiftNetwork.stopMatchListeners();
    },

    clearError(): void {
        store.dispatch(setError(""));
    },

    async start(): Promise<void> {
        store.dispatch(setProcessingShift(true));
        const response = await ShiftNetwork.start();
        if (response.success) {
            store.dispatch(setCurrent(response.data.shift));
            Event.dispatch("shift_started");
        }
        store.dispatch(setProcessingShift(false));
    },

    async end(): Promise<void> {
        store.dispatch(setProcessingShift(true));
        const shift = store.getState().shift.current;
        const localAcceptedCount = store.getState().shift.accepted;
        const localPassedCount = store.getState().shift.passed;

        if (
            localAcceptedCount !== shift?.accepted_sessions_count ||
            localPassedCount !== shift?.passed_sessions_count
        ) {
            Logger.log(Log.shift, "shift_counter_mismatch", {
                localAccepted: localAcceptedCount,
                localPassed: localPassedCount,
                remoteAccepted: shift?.accepted_sessions_count,
                remotePassed: shift?.passed_sessions_count
            });
        }

        const response = await ShiftNetwork.end(
            {
                accepted: localAcceptedCount,
                passed: localPassedCount,
                grading_duration: store.getState().shift.timeSpentGrading
            },
            shift?.id
        );
        if (response.success) {
            store.dispatch(setCurrent(null));
            Event.dispatch("shift_ended");
        }
        store.dispatch(setProcessingShift(false));
    },

    handleMatch(match: Match): void {
        if (this.matchAssignment?.matchmakingId === match.matchmakingId) return;
        Logger.event(LoggerEvent.matchAssignmentModal, "", match);
        store.dispatch(setMatchAssignment(match));
        Event.dispatch("shift_match_assigned");
    },

    setSecondsLeft(seconds: number): void {
        store.dispatch(setMatchSecondsLeft(seconds));
    },

    setError(error: string): void {
        store.dispatch(setError(error));
    },

    async claimMatch(matchmakingId: number): Promise<Response> {
        store.dispatch(setProcessingShift(true));
        Logger.event(LoggerEvent.matchClaimRequest);
        const response = await ShiftNetwork.claimMatch(matchmakingId);
        Logger.event(LoggerEvent.matchClaimResponse, "", {
            claimed: response.success,
            reason: response.data?.reason
        });
        if (response.success) {
            store.dispatch(incrementAccepted());
            store.dispatch(setMatchAssignment(null));
            Event.dispatch("shift_match_claimed");
            await syncShift();
        }
        store.dispatch(setProcessingShift(false));
        return response;
    },

    async assignmentPassed(matchmakingId: number): Promise<void> {
        Logger.event(LoggerEvent.matchClaimTimeout);
        store.dispatch(setProcessingShift(true));
        const response = await ShiftNetwork.assignmentPassed(matchmakingId);
        if (response.success) {
            store.dispatch(incrementPassed());
            this.assignmentUnavailable();
            await syncShift();
        }
        store.dispatch(setProcessingShift(false));
    },

    async assignmentReassigned(): Promise<void> {
        Logger.event(LoggerEvent.matchAssignmentPassed);
        store.dispatch(incrementPassed());
        this.assignmentUnavailable();
        store.dispatch(setProcessingShift(true));
        await syncShift();
        store.dispatch(setProcessingShift(false));
    },

    assignmentCanceled(): void {
        Logger.event(LoggerEvent.matchAssignmentCanceled);
        this.assignmentUnavailable();
    },

    assignmentUnavailable(): void {
        store.dispatch(setMatchAssignment(null));
        Event.dispatch("shift_match_unavailable");
    },

    clearMatchAssignment(): void {
        store.dispatch(setMatchAssignment(null));
    },

    get matchAssignment(): Match | null {
        return store.getState().shift.matchAssignment;
    }
};

export default Shift;

async function syncShift() {
    const shift = store.getState().shift.current;
    if (shift) {
        const response = await ShiftNetwork.get(shift.id);
        if (response.success) {
            store.dispatch(setCurrent(response.data));
        }
    }
}

export function currentShift(): ShiftType | null {
    const shift = store.getState().shift.current;
    if (shift?.end_at) return null;
    return shift;
}

export function getShiftInfo() {
    const shift = store.getState().shift.current;
    const accepted = store.getState().shift.accepted;
    const passed = store.getState().shift.passed;
    return {
        shiftId: shift?.id.toString() ?? "",
        sessionCountPass: shift?.passed_sessions_count.toString(),
        sessionCountAccepted: shift?.accepted_sessions_count.toString(),
        localCountAccepted: accepted.toString(),
        localCountPassed: passed.toString()
    };
}

export function getShiftLogInfo() {
    return {
        tutor_shift_id: store.getState().shift.current?.id
    };
}

export function incrementTimeSpentGrading(duration: number) {
    if (currentShift()) {
        store.dispatch(incrementTimeSpentGradingStore(duration));
    }
}

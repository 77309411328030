import {
    NetworkHelper as ApiNetworkHelper,
    NetworkHelperClass,
    LocalStorageHelper,
    LogEvent,
    Timers,
    DebugLogger,
    CallbackParams as RequestParams,
    Queue
} from "@yups/utils";
import { Connection } from "models/Connection";
import { StoreKey } from "store/StoreKey";
import Debug, { DebugLog } from "./Debug";

export type NetworkHealthMetric = {
    startAt: number;
    endPoint: string;
    endAt?: number;
    success?: boolean;
    requestCompleted?: boolean;
    error?: Error | { [key: string]: any }; // TODO: consolidate error types in NetworkHelper util
};

const BACKEND_PING_ENDPOINT = "/api/network_health_monitor/mark_online";
const TIMER_LABEL_PING = "network_health_monitor.ping";
const TIMER_LABEL_REPORT_PING = "network_health_monitor.report_ping";
const TIMER_LABEL_CONNECTIVITY_CHECK =
    "network_health_monitor.check_connectivity";
const TIMER_PING_INTERVAL = 5000;
const CONNECTIVITY_CHECK_INTERVAL = 15000;
const TIMER_REPORT_INTERVAL = 10000;

type Rtts = { [key: string]: Array<number> };

class NetworkHealthMonitorClass {
    public localePingEndpoint?: string;
    public eventPayloadCallback: () => {} = () => {
        return {};
    };

    private rawMetrics: { [key: string]: NetworkHealthMetric } = {};
    private backendApiRtts: Rtts = {};
    private backendPingRtts: Rtts = {};
    private localePingRtts: Rtts = {};
    private debugLogger = new DebugLogger("🌐 NetworkHealthMonitor");
    private persistOnUnload = true;
    private thirdPartyNetworkHelper = new NetworkHelperClass({ headers: {} });
    private recentPings: Queue<boolean> = new Queue("recent-pings");

    constructor() {
        this.backendPingRtts[BACKEND_PING_ENDPOINT] = [];
    }

    startMonitoring(networkHelpers: NetworkHelperClass[]) {
        this.debugPrint("starting monitoring");

        this.loadStoredMetrics();

        new Set([
            this.thirdPartyNetworkHelper,
            ApiNetworkHelper,
            ...networkHelpers
        ]).forEach((networkHelper: NetworkHelperClass) => {
            networkHelper.onStart((params) => this.onRequestStart(params));
            networkHelper.onSuccess((params) => this.onRequestSuccess(params));
            networkHelper.onError((params) => this.onRequestError(params));
            networkHelper.onEnd((params) => this.onRequestEnd(params));
        });
    }

    startPing() {
        this.debugPrint("starting ping monitoring");

        this.persistOnUnload = true;

        Timers.setRecursiveTimeout({
            label: TIMER_LABEL_PING,
            callback: async () => this.sendPings(),
            delay: TIMER_PING_INTERVAL
        });
        Timers.setRecursiveTimeout({
            label: TIMER_LABEL_REPORT_PING,
            callback: async () => this.reportPingMetrics(),
            delay: TIMER_REPORT_INTERVAL
        });
        Timers.setRecursiveTimeout({
            label: TIMER_LABEL_CONNECTIVITY_CHECK,
            callback: async () => this.checkConnectivity(),
            delay: CONNECTIVITY_CHECK_INTERVAL
        });
    }

    stopPing() {
        this.debugPrint("stopping ping monitoring");

        this.persistOnUnload = false;

        Timers.clear(TIMER_LABEL_PING);
        Timers.clear(TIMER_LABEL_REPORT_PING);
        Timers.clear(TIMER_LABEL_CONNECTIVITY_CHECK);
    }

    onBeforeUnload() {
        if (this.persistOnUnload) {
            this.saveToLocalStorage();
        }
    }

    toString() {
        return `backendApiRtt.length ${
            Object.values(this.backendApiRtts).flat().length
        }, backendPingRtt.length ${
            Object.values(this.backendPingRtts).flat().length
        }, localePingRtt.length ${
            Object.values(this.localePingRtts).flat().length
        }`;
    }

    private onRequestStart(params: RequestParams) {
        if (
            params.endPoint.includes("log_entries") &&
            JSON.stringify(params.params).includes("network_health_monitor")
        ) {
            return;
        }

        this.rawMetrics[params.uuid] = {
            endPoint: params.endPoint,
            startAt: new Date().getTime() / 1000
        };
        this.backendApiRtts[params.endPoint] =
            this.backendApiRtts[params.endPoint] ?? [];
        this.localePingRtts[params.endPoint] =
            this.localePingRtts[params.endPoint] ?? [];
    }

    private onRequestSuccess(params: RequestParams) {
        const metric = this.rawMetrics[params.uuid];
        if (metric) metric.success = true;
    }

    private onRequestError(params: RequestParams) {
        const metric = this.rawMetrics[params.uuid];
        if (metric) {
            metric.success = false;
            metric.error = params.error;
        }
    }

    private onRequestEnd(params: RequestParams) {
        if (params.endPoint === BACKEND_PING_ENDPOINT) {
            this.addPingResult(params);
        }
        const metric = this.rawMetrics[params.uuid];
        if (!metric) return;

        metric.endAt = new Date().getTime() / 1000;
        metric.requestCompleted = params.fetchCompleted;

        this.flushMetric(metric, params.uuid);
    }

    private flushMetric(metric: NetworkHealthMetric, uuid: string) {
        this.updateRtts(metric);
        this.reportBackendApiMetric(metric);
        delete this.rawMetrics[uuid];
    }

    private addPingResult(params: RequestParams) {
        const maxSize = CONNECTIVITY_CHECK_INTERVAL / TIMER_PING_INTERVAL;
        if (this.recentPings.length === maxSize) this.recentPings.dequeue();

        this.recentPings.enqueue(!params.error && params.fetchCompleted);
    }

    checkConnectivity() {
        const successes = this.recentPings.toArray().filter(Boolean);
        const average = successes.length / (this.recentPings.length ?? 1);
        let status = "offline";
        if (average > 0.95) {
            status = "connected";
            Connection.handleOnline();
        } else if (average > 0.25) {
            status = "slow";
            Connection.handleSlowConnection();
        } else {
            Connection.handleOffline();
        }

        this.logEvent("network_health_monitor.connectivity", {
            average,
            status
        });
    }

    private async sendPings() {
        if (this.localePingEndpoint) {
            await Promise.all([
                this.thirdPartyNetworkHelper.get({
                    endPoint: this.localePingEndpoint
                }),
                ApiNetworkHelper.put({
                    endPoint: BACKEND_PING_ENDPOINT,
                    params: {}
                })
            ]);
        } else {
            await ApiNetworkHelper.put({
                endPoint: BACKEND_PING_ENDPOINT,
                params: {}
            });
        }
    }

    private reportPingMetrics() {
        this.logEvent("network_health_monitor.ping", {
            ...this.rttPercentiles()
        });
    }

    private reportBackendApiMetric(metric: NetworkHealthMetric) {
        if (!this.isBackendApi(metric)) return;

        const { endAt, startAt, error, ...payloadData } = metric,
            rttValue = endAt! - startAt;
        this.logEvent("network_health_monitor.api", {
            monitor: {
                type: "time",
                value: rttValue,
                tags: `endpoint:${this.getFormattedEndPoint(metric.endPoint)}`
            },
            rtt_value: rttValue,
            error: error?.message ? { error: error.message } : error,
            ...this.rttPercentiles(metric),
            ...payloadData,
            endPoint: this.getFormattedEndPoint(payloadData.endPoint)
        });
    }

    private rttPercentiles(metric?: NetworkHealthMetric) {
        const calcPrecentile = (rtts: Rtts, precentile: number) => {
            const flatRtts = Object.values(rtts).flat();
            return flatRtts.sort()[Math.floor(flatRtts.length * precentile)];
        };

        const calcRttStats = (rtts: Rtts, type: string) => {
            const stats: { [key: string]: any } = {};
            stats[`rtt_${type}_p50`] = calcPrecentile(rtts, 0.5);
            stats[`rtt_${type}_p95`] = calcPrecentile(rtts, 0.95);

            return stats;
        };

        let result: any = {
            ...calcRttStats(this.backendApiRtts, "backend_api"),
            ...calcRttStats(this.backendPingRtts, "backend_ping"),
            ...calcRttStats(this.localePingRtts, "locale_ping")
        };

        if (metric) {
            result = {
                ...result,
                ...calcRttStats(
                    { endPoint: this.backendApiRtts[metric.endPoint] },
                    "current_api"
                )
            };
        }

        return result;
    }

    private updateRtts(metric: NetworkHealthMetric) {
        if (!metric.requestCompleted) return;

        const isBackendApi = this.isBackendApi(metric);
        const isBackendPing = this.isBackendPing(metric);
        const rtt = metric.endAt! - metric.startAt;

        if (isBackendApi && !isBackendPing) {
            this.backendApiRtts[metric.endPoint].push(rtt);
        } else if (isBackendPing) {
            this.backendPingRtts[metric.endPoint].push(rtt);
        } else {
            this.localePingRtts[metric.endPoint].push(rtt);
        }
    }

    private isBackendPing(metric: NetworkHealthMetric) {
        return metric.endPoint.includes(BACKEND_PING_ENDPOINT);
    }

    private isBackendApi(metric: NetworkHealthMetric) {
        return !this.isBackendPing(metric);
    }

    private loadStoredMetrics() {
        const savedMetric = LocalStorageHelper.getSync(
            StoreKey.networkHealthMonitor
        );

        if (savedMetric) {
            const data: NetworkHealthMonitorClass = JSON.parse(savedMetric);

            this.backendApiRtts = data.backendApiRtts || {};
            this.backendPingRtts = data.backendPingRtts || this.backendPingRtts;
            this.localePingRtts = data.localePingRtts || {};
            LocalStorageHelper.removeSync(StoreKey.networkHealthMonitor);

            this.debugPrint(`Loading rtts values: ${this}`);
        } else {
            this.debugPrint("No saved rtt values to load");
        }
    }

    private saveToLocalStorage() {
        this.debugPrint(`Saving rtt values: ${this}`);

        LocalStorageHelper.setSync(
            StoreKey.networkHealthMonitor,
            JSON.stringify({
                backendApiRtts: this.backendApiRtts,
                backendPingRtts: this.backendPingRtts,
                localePingRtts: this.localePingRtts
            })
        );
    }

    private debugPrint(message: string): void {
        if (Debug.get(DebugLog.Observers)) this.debugLogger.info(message);
    }

    private getFormattedEndPoint(endPoint: string) {
        const path = endPoint.startsWith("http")
            ? endPoint.split(".com").pop()
            : endPoint;
        const tag = path!
            .split("?")[0]
            .replace(/\//g, "_")
            .replace(/^_|_$|_\d+/g, "");

        return tag;
    }

    private logEvent(name: string, payload: any) {
        LogEvent(name, {
            ...payload,
            ...this.eventPayloadCallback()
        });
    }
}

export const NetworkHealthMonitor = new NetworkHealthMonitorClass();

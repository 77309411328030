import { LineStyle } from "../types/LineStyle";
import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";
import { getLineDashStyle } from "../helpers/lineDashStyle";
import { NodeType } from "../types/NodeType";

export const lineWidth: BaseCommand = {
    async execute(
        context: ZwibblerContext,
        props: {
            tool: WhiteboardAction;
            width: number;
            size: string;
        }
    ) {
        context.setToolProperty("lineWidth", props.width);

        // Only change lines and shapes
        context.getSelectedNodes().forEach((nodeId) => {
            if (
                context.getNodeType(nodeId) === NodeType.Path ||
                context.getNodeType(nodeId) === NodeType.Brush
            ) {
                context.setNodeProperty(nodeId, "lineWidth", props.width);

                // Make sure dashes maintain proportions
                const currentDashes = context.getNodeProperty(nodeId, "dashes");
                const isDashed = /([0-9]+),([0-9]+)/g.exec(currentDashes);
                if (isDashed) {
                    if (isDashed[1] === isDashed[2]) {
                        context.setNodeProperty(
                            nodeId,
                            "dashes",
                            getLineDashStyle(LineStyle.dotted, props.width)
                        );
                    } else {
                        context.setNodeProperty(
                            nodeId,
                            "dashes",
                            getLineDashStyle(LineStyle.dashed, props.width)
                        );
                    }
                }
            }
        });
        LogAction(context, props.tool, {
            thickness_changed: props.size
        });
    }
};

export default lineWidth;

import Sentry from "helpers/Sentry";
import {
    OnboardingBackgroundCheckStatus,
    OnboardingBackgroundPayload,
    OnboardingBioPayload,
    OnboardingExam,
    OnboardingExamAnswerPayload,
    OnboardingStatus
} from "shared/types/Onboarding";
import WebService from "helpers/WebService";
import {
    setSettings,
    setBackgroundInfo,
    setExam,
    setExamQuestions,
    setExamReview,
    setBackgroundCheckStatus,
    setContract,
    setError,
    setContractReview
} from "store/Onboarding";
import store from "store";
import { formatTimestamp, interpolateString } from "helpers/String";
import { getUser, User } from "./User";

enum ErrorMessages {
    errorTooYoung = "You must be 18 or older to tutor.",
    errorSubmitCheckFailed = "There was a problem submitting your background check. Please try again or email {{email}}.",
    errorDefault = "Failed to load. Please refresh the page."
}

const Onboarding = {
    getExam() {
        return store.getState().onboarding.exam;
    },
    clearError() {
        store.dispatch(setError(""));
    },
    async updateStatus(status: OnboardingStatus): Promise<void> {
        try {
            await WebService.updateOnboardingStatus(status);
            await User.fetch();
        } catch (error) {
            Sentry.logError(error);
        }
    },
    async loadBackgroundData(): Promise<void> {
        const response = await WebService.getOnboardingBackgroundData();
        if (response.success) {
            store.dispatch(setBackgroundInfo(response.data.tutor_bio));
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async submitBackgroundData(info: OnboardingBackgroundPayload) {
        const birthDate = new Date(info.date_of_birth);
        const today = new Date();
        const ageMin = new Date(
            today.getFullYear() - 18,
            today.getMonth(),
            today.getDate()
        );
        if (ageMin.getTime() < birthDate.getTime()) {
            store.dispatch(setError(ErrorMessages.errorTooYoung));
            return;
        }

        const response = await WebService.submitOnboardingBackgroundData({
            education: "",
            accomplishments: "",
            gender: info.gender,
            date_of_birth: formatTimestamp(
                birthDate.getTime() / 1000,
                "yyyy-MM-dd"
            ),
            resume_url: info.resume_url,
            interests: info.interests,
            raw_bio: {
                ...info,
                date_of_birth: formatTimestamp(
                    birthDate.getTime() / 1000,
                    "yyyy-MM-dd"
                )
            }
        });
        if (response.success) {
            await this.updateStatus(OnboardingStatus.subject_exam);
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
        return response.success;
    },
    async loadSettings(): Promise<void> {
        const examResponse = await WebService.getOnboardingExamData();
        const settingResponse = await WebService.getOnboardingData();
        if (examResponse.success && settingResponse.success) {
            store.dispatch(
                setSettings({
                    ...examResponse.data,
                    ...settingResponse.data.tutor_dashboard_settings
                })
            );
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async loadExam(id: number): Promise<void> {
        const response = await WebService.getOnboardingExam(id);
        if (response.success) {
            store.dispatch(setExam(response.data));
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async loadExamQuestions(): Promise<void> {
        const exam = this.getExam();
        if (!exam) return;

        const response = await WebService.getOnboardingExamQuestions(exam.id);
        if (response.success) {
            store.dispatch(setExamQuestions(response.data));
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async submitAnswer(answer: OnboardingExamAnswerPayload): Promise<void> {
        const exam = this.getExam();
        const user = getUser();
        if (!exam || !user) return;

        const response = await WebService.submitOnboardingExamAnswer({
            ...answer,
            exam_id: exam.id
        });
        if (response.success) {
            await this.loadExam(exam.id);
            if (
                this.getExam()?.passed &&
                user.application.onboarding_status.name ===
                    OnboardingStatus.subject_exam
            ) {
                await this.updateStatus(OnboardingStatus.teaching_exam);
            }
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async getExamResult(): Promise<void> {
        const exam = this.getExam();
        if (!exam) return;
        await this.loadExam(exam.id);

        const response = await WebService.getOnboardingExamResult(exam.id);
        await User.fetch();
        if (response.success) {
            store.dispatch(
                setExam({
                    ...exam,
                    ...response.data
                })
            );
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async getBackgroundCheck() {
        const response = await WebService.getBackgroundCheck();
        if (response.success) {
            store.dispatch(setBackgroundCheckStatus(response.data.status));
        }
    },
    async submitBackgroundCheck(country: string, state?: string) {
        const response = await WebService.submitBackgroundCheck(country, state);
        if (response.success) {
            store.dispatch(
                setBackgroundCheckStatus(
                    OnboardingBackgroundCheckStatus.invited
                )
            );
        } else {
            store.dispatch(
                setError(
                    interpolateString(ErrorMessages.errorSubmitCheckFailed, {
                        email: store.getState().onboarding.settings
                            .tutor_support_email
                    })
                )
            );
        }
    },

    async loadContract() {
        const user = getUser();
        if (!user) return;
        const response = await WebService.getContract(
            user.application.unique_token
        );
        if (response.success) {
            store.dispatch(setContract(response.data.contract));
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async submitContract(contract: string) {
        const response = await WebService.submitContract(contract);
        if (response.success) {
            await this.updateStatus(OnboardingStatus.tutor_bio);
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
        return response.success;
    },
    async submitBio(bio: OnboardingBioPayload) {
        const response = await WebService.submitBio(bio);
        if (response.success) {
            await this.updateStatus(OnboardingStatus.waiting_for_schedule);
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
        return response.success;
    },
    async getExamReview(examId: number): Promise<void> {
        if (!User.isAdmin()) return;

        const response = await WebService.getOnboardingExamReview(examId);
        if (response.success) {
            store.dispatch(setExamReview(response.data));
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    },
    async getContractReview(token: string) {
        const response = await WebService.getContract(token);
        if (response.success) {
            store.dispatch(setContractReview(response.data.contract));
        } else {
            store.dispatch(setError(ErrorMessages.errorDefault));
        }
    }
};

export function examIsFinished(exam: OnboardingExam) {
    if (!exam) return false;
    return (
        exam.exam_question_responses === exam.questions_map.length ||
        exam.should_end_in_seconds <= 0
    );
}

export default Onboarding;

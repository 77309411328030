export interface ConfigInterface {
    assetsURL: string;
    matchClaimTimeoutInSeconds: number;
    sessionReviewTimeoutInSeconds: number;
    sessionFeedbackTimeoutInSeconds: number;
    studentNoActivityWarningDelayInSeconds: number;
    studentInactivityWarningDelayInSeconds: number;
    sessionInactivityWarningDelayInSeconds: number;
    newsfeedUrl: string;
    usersnapApiKey: string;
    usersnapTutorApiKey: string;
    usersnapApplicantApiKey: string;
    sentryUrl: string;
    zwibblerServerUrl: string;
    buildId: string;
    commitRef: string;
    onboardingRedirectInSeconds: number;
}

const Config: ConfigInterface = {
    assetsURL: "https://d357l0ew0ieswo.cloudfront.net",
    matchClaimTimeoutInSeconds: 15,
    sessionReviewTimeoutInSeconds: 40,
    sessionFeedbackTimeoutInSeconds: 150,
    studentNoActivityWarningDelayInSeconds: 120,
    studentInactivityWarningDelayInSeconds: 420,
    sessionInactivityWarningDelayInSeconds: 120,
    newsfeedUrl: "https://newsfeed.yupinternal.com",
    usersnapApiKey: process?.env?.REACT_APP_USERSNAP_KEY ?? "",
    usersnapTutorApiKey: process?.env?.REACT_APP_USERSNAP_TUTOR_KEY ?? "",
    usersnapApplicantApiKey:
        process?.env?.REACT_APP_USERSNAP_APPLICANT_KEY ?? "",
    sentryUrl: process?.env?.REACT_APP_SENTRY_URL ?? "",
    zwibblerServerUrl: process?.env?.REACT_APP_ZWIBBLER_SERVER_URL ?? "",
    buildId: process?.env?.REACT_APP_NETLIFY_BUILD_ID ?? "",
    commitRef: process?.env?.REACT_APP_NETLIFY_COMMIT_REF ?? "",
    onboardingRedirectInSeconds: 30
};

export { Config };

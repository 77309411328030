import { NetworkHelper, retry } from "@yups/utils";
import { Response } from "shared/types/Response";
import { SessionUpdateTypes } from "shared/types/Session";
import {
    OnboardingBioPayload,
    OnboardingExamAnswerPayload,
    OnboardingStatus
} from "shared/types/Onboarding";
import { MessageType } from "shared/components/ChatRoom/types/Message";
import { SubmitScorePayload } from "shared/types/SessionReview";
import Usersnap from "helpers/Usersnap";
import { onUnauthorized } from "models/User";

import Debug, { DebugLog } from "helpers/Debug";

import quickResponses from "shared/data/quick-responses.json";
import achievements from "shared/data/achievements.json";
import { OnboardingBackgroundPayload } from "shared/types/Onboarding";
import Sentry from "./Sentry";
import FeatureDecision from "models/FeatureDecision";
import {
    EndSessionRequest,
    SubmitPostSessionFeedbackRequest,
    YupAPI
} from "./YupAPI";
import { SessionUpdate } from "models/SessionNetwork";

const statusTag = {
    success: "success:true",
    error: "success:false"
};

enum Method {
    GET = "get",
    POST = "post",
    PUT = "put"
}

let retryCount = 0;
const WebService = {
    setRetries: function (retries: number) {
        retryCount = retries;
    },

    serverTime: async function (): Promise<Response> {
        if (FeatureDecision.enableYupAPI("serverTime")) {
            return await YupAPI.serverTime();
        }
        return await makeRequest(Method.GET, "/api/settings/server_time");
    },

    getUser: async function (): Promise<Response> {
        return await makeRequest(Method.GET, "/api/user");
    },

    login: async function (info: LoginRequest) {
        return await makeRequest(
            Method.POST,
            "/api/login?source=tutor_dashboard",
            info
        );
    },

    forgotPassword: async function (info: ForgotPasswordRequest) {
        return await makeRequest(
            Method.POST,
            "/api/users/request_reset_password_email",
            info
        );
    },

    resetPassword: async function (info: ResetPasswordRequest) {
        return await makeRequest(
            Method.POST,
            "/api/users/reset_password",
            info
        );
    },

    getPreOnboardingData: async function () {
        return await makeRequest(
            Method.GET,
            "/api/tutor_application/pre_login_data"
        );
    },
    validateOnboardingData: async function (email: string) {
        return await makeRequest(
            Method.POST,
            "/api/tutor_application/check_existing_email",
            { email }
        );
    },
    submitOnboarding: async function (info: SubmitOnboardingRequest) {
        return await makeRequest(
            Method.POST,
            "/api/tutor_application/new_application",
            info
        );
    },
    getOnboardingData: async function () {
        return await makeRequest(Method.GET, "/api/runtime", {
            for_tutor_dashboard: true
        });
    },
    getOnboardingBackgroundData: async function () {
        return await makeRequest(
            Method.GET,
            "/api/tutor_dashboard/tutor_bio_params"
        );
    },
    submitOnboardingBackgroundData: async function (
        info: SubmitOnboardingBackgroundRequest
    ) {
        return await makeRequest(Method.POST, "/api/tutor/bio_info", info);
    },

    getOnboardingExamData: async function () {
        return await makeRequest(
            Method.GET,
            "/api/tutor_dashboard/onboarding_params"
        );
    },
    getOnboardingExam: async function (subject_id: number) {
        return await makeRequest(Method.GET, `/api/exam`, { subject_id });
    },
    getOnboardingExamQuestions: async function (id: number) {
        return await makeRequest(Method.GET, `/api/exam/${id}/questions`);
    },
    submitOnboardingExamAnswer: async function (
        info: OnboardingExamAnswerPayload
    ) {
        return await makeRequest(
            Method.POST,
            `/api/exam/question_response`,
            info
        );
    },
    getOnboardingExamResult: async function (id: number) {
        return await makeRequest(Method.GET, `/api/exam/${id}/result`);
    },
    getOnboardingExamReview: async function (id: number) {
        return await makeRequest(Method.GET, `/api/exam/${id}`);
    },
    getBackgroundCheck: async function () {
        return await makeRequest(Method.GET, `/api/background_check`);
    },
    submitBackgroundCheck: async function (country: string, state?: string) {
        return await makeRequest(Method.POST, `/api/background_check`, {
            country,
            state
        });
    },
    getContract: async function (token: string) {
        return await makeRequest(Method.GET, `/api/contract_document/${token}`);
    },
    submitContract: async function (contract: string) {
        return await makeRequest(Method.POST, `/api/contract`, {
            body: contract
        });
    },
    submitBio: async function (detailed_bio: OnboardingBioPayload) {
        return await makeRequest(Method.POST, `/api/tutor/detailed_bio_info`, {
            detailed_bio
        });
    },

    getCurrentShift: async function () {
        return await makeRequest(Method.GET, `/api/tutor_shifts`);
    },

    shiftStart: async function () {
        return await makeRequest(Method.POST, "/api/tutor_shifts/start");
    },

    shiftEnd: async function (payload: EndShiftRequest) {
        return await makeRequest(Method.PUT, "/api/tutor_shifts/end", payload);
    },

    shiftGet: async function (shiftId: number) {
        return await makeRequest(Method.GET, `/api/tutor_shifts/${shiftId}`);
    },

    matchClaim: async function (matchmakingId: number) {
        if (FeatureDecision.enableYupAPI("matchClaim")) {
            return await YupAPI.matchClaim(matchmakingId);
        }
        return await makeRequest(Method.PUT, "/api/match_maker/claim_match", {
            id: matchmakingId
        });
    },

    matchTimeout: async function (matchmakingId: number) {
        if (FeatureDecision.enableYupAPI("matchTimeout")) {
            return await YupAPI.matchTimeout(matchmakingId);
        }
        return await makeRequest(Method.PUT, "/api/match_maker/claim_timeout", {
            id: matchmakingId
        });
    },

    sessionGet: async function (sessionId: number) {
        if (FeatureDecision.enableYupAPI("sessionGet")) {
            return YupAPI.sessionGet(sessionId);
        }

        return await makeRequest(
            Method.GET,
            `/api/math_crunch_sessions/${sessionId}`,
            { omit_messages: true }
        );
    },

    sessionUpdate: async function (
        sessionId: number,
        info: SessionUpdateTypes,
        updateType: SessionUpdate
    ) {
        if (FeatureDecision.enableYupAPI("sessionUpdate")) {
            if (updateType === "endSession") {
                return YupAPI.endSession(sessionId, info as EndSessionRequest);
            } else if (updateType === "feedback") {
                return YupAPI.submitPostSessionFeedback(
                    sessionId,
                    info as SubmitPostSessionFeedbackRequest
                );
            }
        }

        // Backward compatability if this endpoint is reverted
        const params: any = { ...info };
        if (updateType === "feedback") {
            params.started_explaining = true;
            params.solved_problem = true;
        }

        return await makeRequest(
            Method.PUT,
            `/api/math_crunch_sessions/${sessionId}`,
            params
        );
    },

    sessionTutorReady: async function (sessionId: number) {
        if (FeatureDecision.enableYupAPI("sessionTutorReady")) {
            return YupAPI.tutorReady(sessionId);
        } else {
            return await makeRequest(
                Method.PUT,
                "/api/math_crunch_sessions/tutor_ready",
                { math_crunch_session_id: sessionId }
            );
        }
    },

    sessionSendMessage: async function (message: MessageType) {
        if (FeatureDecision.enableYupAPI("sessionSendMessage")) {
            return await YupAPI.sessionSendMessage(message);
        }

        return await makeRequest(Method.POST, "/api/message", message);
    },

    sessionGetNewMessages: async function (
        sessionId: number,
        latestMessageId: number
    ) {
        if (FeatureDecision.enableYupAPI("sessionGetNewMessages")) {
            return await YupAPI.sessionGetNewMessages(
                sessionId,
                latestMessageId
            );
        }
        return await makeRequest(
            Method.GET,
            `/api/math_crunch_sessions/${sessionId}/latest-messages/${latestMessageId}`
        );
    },

    sessionSendLatestMessageId: async function (
        sessionId: number,
        latestMessageId: number
    ) {
        if (FeatureDecision.enableYupAPI("sessionSendLatestMessageId")) {
            return await YupAPI.sessionSendLatestMessageId(
                sessionId,
                latestMessageId
            );
        }

        return await makeRequest(
            Method.POST,
            `/api/math_crunch_sessions/${sessionId}/latest-messages/${latestMessageId}`
        );
    },

    getNewDeliveries: async function (sessionId: number) {
        if (FeatureDecision.enableYupAPI("getNewDeliveries")) {
            return await YupAPI.getNewDeliveries(sessionId);
        }
        return await makeRequest(
            Method.GET,
            `/api/math_crunch_sessions/${sessionId}/latest-messages-status`
        );
    },
    getWhiteboardCaptures: async function (session_id: number) {
        if (FeatureDecision.enableYupAPI("getWhiteboardCaptures")) {
            return await YupAPI.getWhiteboardCaptures(session_id);
        }
        return await makeRequest(Method.GET, `/api/whiteboard_captures`, {
            session_id
        });
    },
    saveWhiteboardCapture: async function (
        session_id: number,
        url: string,
        captured_at: number
    ) {
        if (FeatureDecision.enableYupAPI("saveWhiteboardCapture")) {
            return await YupAPI.saveWhiteboardCapture(
                session_id,
                url,
                captured_at
            );
        }
        return await makeRequest(Method.POST, "/api/whiteboard_capture", {
            session_id,
            url,
            captured_at
        });
    },
    submitSessionFeedback: async function (
        sessionId: number,
        feedback: SubmitPostSessionFeedbackRequest
    ) {
        if (FeatureDecision.enableYupAPI("submitSessionFeedback")) {
            return await YupAPI.submitSessionFeedback(sessionId, feedback);
        }

        return await makeRequest(
            Method.PUT,
            `/api/math_crunch_sessions/${sessionId}`,
            feedback
        );
    },

    nextSessionReview: async function () {
        if (FeatureDecision.enableYupAPI("nextSessionReview")) {
            return await YupAPI.nextSessionToScore();
        }

        return await makeRequest(Method.GET, "/api/session_gradings/next");
    },

    legacyGetSessionReview: async function (sessionReviewId: number) {
        return await makeRequest(
            Method.GET,
            `/api/session_feedback/${sessionReviewId}`
        );
    },

    getSessionGrading: async function (sessionReviewId: number) {
        if (FeatureDecision.enableYupAPI("getSessionGrading")) {
            return await YupAPI.fetchScore(sessionReviewId);
        }
        return await makeRequest(
            Method.GET,
            `/api/session_gradings/${sessionReviewId}`
        );
    },

    getLibraryCards: async function () {
        if (FeatureDecision.enableYupAPI("getLibraryCards")) {
            return await YupAPI.getLibraryCards();
        }
        return await makeRequest(Method.GET, "/api/library_cards");
    },

    getQuickResponses: async function () {
        return Promise.resolve({
            success: true,
            data: quickResponses
        });
    },

    getAchievements: async function () {
        return Promise.resolve({
            success: true,
            data: achievements
        });
    },

    getTutorRubric: async function () {
        return await makeRequest(Method.GET, "/api/tutor_rubric");
    },

    submitSessionReview: async function (
        sessionReviewId: number,
        payload: SubmitScorePayload
    ) {
        if (FeatureDecision.enableYupAPI("submitSessionReview")) {
            return await YupAPI.submitScore(sessionReviewId, payload);
        }

        return await makeRequest(
            Method.PUT,
            `/api/session_gradings/${sessionReviewId}`,
            payload
        );
    },

    getWorkbook: async function () {
        return await makeRequest(Method.GET, `/api/tutor_workbook/v2`);
    },

    getFavoritedBy: async function () {
        return await makeRequest(Method.GET, `/api/favoriting_students`);
    },

    getWorkbookSessions: async function (payload: WorkbookSessionsRequest) {
        if (FeatureDecision.enableYupAPI("getWorkbookSessions")) {
            return await YupAPI.getWorkbookSessions(payload);
        }
        return await makeRequest(
            Method.GET,
            `/api/tutor_workbook/sessions`,
            payload
        );
    },
    getUserAdminDetails: async function (user_token: string) {
        return await makeRequest(Method.GET, `/api/user_for_admin`, {
            user_token
        });
    },

    updateOnboardingStatus: async function (status: OnboardingStatus) {
        return await makeRequest(
            Method.PUT,
            `/api/tutor_user/onboarding_status`,
            { name: status }
        );
    },

    getFeatures: async function () {
        return await makeRequest(Method.GET, `/api/features`);
    },

    getAssignedMatch: async function () {
        console.log(FeatureDecision.enableYupAPI("getAssignedMatch"));
        if (FeatureDecision.enableYupAPI("getAssignedMatch")) {
            return await YupAPI.assignedMatchmaking();
        }

        return await makeRequest(
            Method.GET,
            `/api/match_maker/assigned`,
            {},
            true
        );
    }
};

export default WebService;

NetworkHelper.onError((error) => {
    if (error.endPoint?.includes("log_entries")) {
        return;
    }

    Usersnap.addErrorLog(
        `${new Date().toISOString()} [API Error] ${error.method} ${
            error.endPoint
        } threw error: ${statusTag.error} ${error.error?.message}`
    );

    Sentry.logError(error.error);
});

const inProgress: { [key: string]: boolean } = {};
async function makeRequest(
    method: Method,
    endPoint: string,
    params: any = {},
    disableParallelRequests: boolean = false
): Promise<Response> {
    const response: Response = { success: false };

    const retries = retryCount;
    retryCount = 0;

    if (inProgress[endPoint] && disableParallelRequests) {
        return { success: false };
    }

    inProgress[endPoint] = true;

    try {
        await retry(async () => {
            const ts = new Date();

            const result = await NetworkHelper[method]({
                endPoint,
                params
            });

            if (Debug.get(DebugLog.APIRequests)) {
                const delta = new Date().getTime() - ts.getTime();
                if (delta > 500) {
                    // highlight requests that take over 500ms in red
                    console.log(
                        `%c [API REQUEST] ${endPoint} - ${delta}ms`,
                        "color: red"
                    );
                } else {
                    console.log(`[API REQUEST] ${endPoint} - ${delta}ms`);
                }
            }

            if (!("success" in result)) {
                // if the success flag isn't set, use status_code === 200
                // to determine if the request was successful
                result.success = result.status_code === 200;
            }

            if (result.status_code === 401) {
                onUnauthorized();
            }

            response.data = result;
            response.success = Boolean(result.success);
        }, retries);
    } catch (error) {
    } finally {
        inProgress[endPoint] = false;
    }

    return response;
}

// Request types.

type LoginRequest = {
    include_settings: boolean;
    user: {
        email: string;
        password: string;
    };
};

type SubmitOnboardingRequest = {
    email: string;
    password: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    referral_source: string;
};

type SubmitOnboardingBackgroundRequest = {
    education: string;
    accomplishments: string;
    interests: string;
    gender: string;
    raw_bio: OnboardingBackgroundPayload | { date_of_birth: string };
    date_of_birth: string;
    resume_url: string;
};

type ForgotPasswordRequest = {
    include_settings: boolean;
    email: string;
};

type ResetPasswordRequest = {
    password: string;
    reset_token: string;
};

export type WorkbookSessionsRequest = {
    page: number;
    per_page: number;
};

export type EndShiftRequest = {
    accepted: number;
    passed: number;
    grading_duration: number;
};

import { configureStore } from "@reduxjs/toolkit";
import sessionReducer, { init as initSession } from "./Session";
import sessionReviewReducer, {
    init as initSessionReview
} from "./SessionReview";
import settingsReducer, { init as initSettings } from "./Settings";
import shiftReducer, { init as initShift } from "./Shift";
import practiceSessionReducer from "./PracticeSession";
import uiReducer from "./UI";
import userReducer, { init as initUser } from "./User";
import whiteboardReducer from "./Whiteboard";
import workbookReducer, { init as initWorkbook } from "./Workbook";
import onboardingReducer from "./Onboarding";
import deviceReducer, { init as initDevice } from "./Device";

export const store = configureStore({
    reducer: {
        ui: uiReducer,
        session: sessionReducer,
        sessionReview: sessionReviewReducer,
        settings: settingsReducer,
        shift: shiftReducer,
        user: userReducer,
        whiteboard: whiteboardReducer,
        workbook: workbookReducer,
        practiceSession: practiceSessionReducer,
        onboarding: onboardingReducer,
        device: deviceReducer
    }
});

export function initStore() {
    store.dispatch(initUser());
    store.dispatch(initSettings());
    store.dispatch(initShift());
    store.dispatch(initWorkbook());
    store.dispatch(initDevice());
    store.dispatch(initSession());
    store.dispatch(initSessionReview());
}

export default store;

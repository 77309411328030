export enum WhiteboardAction {
    addPage = "addPage",
    removePage = "removePage",
    selectPage = "selectPage",
    movePage = "movePage",
    uploadImage = "uploadImage",
    clear = "clear",
    send = "send",
    select = "select",
    fillOpacity = "fillOpacity",
    strokeOpacity = "strokeOpacity",
    highlight = "highlighter",
    pencil = "pencil",
    eraser = "eraser",
    line = "line",
    curve = "curve",
    arrow = "arrow",
    doubleArrow = "doubleArrow",
    delete = "delete",
    text = "text",
    shape = "shape",
    undo = "undo",
    redo = "redo",
    background = "background",
    snap = "snap",
    polygon = "polygon",
    upload = "upload",
    download = "download",
    zoomIn = "zoomIn",
    zoomOut = "zoomOut",
    strokeColor = "strokeColor",
    fillColor = "fillColor",
    lineWidth = "lineWidth",
    lineStyle = "lineStyle",
    equation = "equation",
    makeCurrentPageLive = "makeCurrentPageLive",
    resetDefaults = "resetDefaults"
}

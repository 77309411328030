import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogPageChange } from "./BaseCommand";

export const removePage: BaseCommand = {
    execute(context: ZwibblerContext, page: number) {
        if (
            window.confirm(`Are you sure you would like to remove this page?`)
        ) {
            context.deletePage(page);
            LogPageChange(page, "removed");
        }
    }
};

export default removePage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { UserType } from "shared/types/User";
import store from "store";
import { StoreKey } from "store/StoreKey";

const storePrefix = StoreKey.user;
const storage = new Storage(storePrefix, clearUser);
function clearUser(): void {
    store.dispatch(userSlice.actions.setUser(null));
}

type UserStateType = {
    user: UserType | null;
    error: string;
};

const userSlice = createSlice({
    name: "user",
    initialState: {} as UserStateType,
    reducers: {
        setUser: (state, action: PayloadAction<UserType | null>) => {
            state.user = action.payload;
            updateCache(state);
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        init(state) {
            if (!state.user) state.user = storage.get();
        }
    }
});

export default userSlice.reducer;

export const { init, setUser, setError } = userSlice.actions;

function updateCache(state: UserStateType) {
    if (state.user) {
        storage.set(state.user);
    } else {
        storage.remove();
    }
}

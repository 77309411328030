import SessionReviewNetwork from "./SessionReviewNetwork";
import store from "store/index";
import { getUserAdminDetails } from "models/User";
import { UserType } from "shared/types/User";

import { Response } from "shared/types/Response";
import { RubricSection } from "shared/types/Rubric";
import { Session } from "shared/types/Session";
import { SessionTag } from "shared/types/SessionTag";

import {
    SubmitScorePayload,
    SessionReviewFeedback,
    SessionReviewFeedbackDefaults
} from "shared/types/SessionReview";
import { Rubric, getRubricVersion, RubricVersion } from "helpers/Rubric";

import {
    setCompletedReview,
    setError,
    setSessionForReview
} from "store/SessionReview";
import { setLoadingSessionReview, setSubmittingSessionReview } from "store/UI";

export type SessionReviewType = {
    id?: number;
    session: Session;
    rubric: Array<RubricSection>;
    session_tags: Array<SessionTag>;
    feedback: SessionReviewFeedback;
    version?: string;
    gradedBy?: UserType;
};

const SessionReviewDefaults = {
    id: undefined,
    session: {} as Session,
    rubric: [],
    session_tags: [],
    feedback: SessionReviewFeedbackDefaults
};

const SessionReview = {
    dismissCurrentReview() {
        this.clearError();
    },

    getCurrentReview() {
        return store.getState().sessionReview.sessionForReview;
    },

    getPastReview() {
        return store.getState().sessionReview.completedReview;
    },

    clearError() {
        store.dispatch(setError(""));
    },

    setError(error: string) {
        store.dispatch(setError(error));
    },

    clearPastSessionReview() {
        store.dispatch(setCompletedReview(null));
    },

    setFeedback(feedback: SessionReviewFeedback) {
        const current = store.getState().sessionReview.sessionForReview;
        if (current) {
            store.dispatch(
                setSessionForReview({
                    ...SessionReviewDefaults,
                    ...current,
                    feedback
                })
            );
        }
    },

    buildResponseData(data: {
        session: Session;
        review?: SessionReviewType;
        tags?: Array<SessionTag>;
        rubric?: Array<RubricSection>;
        feedback?: any;
        version?: string;
        gradedBy?: any;
    }): SessionReviewType {
        const tags =
            data.tags?.map((tag: SessionTag) => ({
                ...tag,
                type: tag.type ?? ""
            })) ?? [];
        return {
            id: data.review?.id ?? SessionReviewDefaults.id,
            session: data.session,
            session_tags: tags ?? SessionReviewDefaults.session_tags,
            rubric: data.rubric ?? SessionReviewDefaults.rubric,
            feedback: {
                ...SessionReviewFeedbackDefaults,
                ...data.feedback
            },
            version: data.version,
            gradedBy: data.gradedBy
        };
    },

    async getNextSessionReview(): Promise<Response> {
        store.dispatch(setLoadingSessionReview(true));
        const nextReviewResponse =
            await SessionReviewNetwork.getNextSessionReview();
        if (!nextReviewResponse.success) {
            store.dispatch(setLoadingSessionReview(false));
            return nextReviewResponse;
        }

        const settingsResponse = await SessionReviewNetwork.getReviewSettings();
        if (!settingsResponse.success) {
            store.dispatch(setLoadingSessionReview(false));
            return settingsResponse;
        }

        const sessionId = nextReviewResponse.data.session.id;
        const sessionResponse = await SessionReviewNetwork.getSession(
            sessionId
        );
        if (!sessionResponse.success) {
            store.dispatch(setLoadingSessionReview(false));
            return sessionResponse;
        }

        const messageResponse = await SessionReviewNetwork.getMessages(
            sessionId
        );
        if (messageResponse.success) {
            sessionResponse.data.messages = messageResponse.data.data.messages;
        }

        store.dispatch(
            setSessionForReview(
                this.buildResponseData({
                    session: sessionResponse.data,
                    review: nextReviewResponse.data,
                    tags: settingsResponse.data.data.tags,
                    rubric: settingsResponse.data.data.pillars
                })
            )
        );
        store.dispatch(setLoadingSessionReview(false));
        return { success: true };
    },

    async getPastSessionReview(sessionId: number): Promise<Response> {
        store.dispatch(setLoadingSessionReview(true));
        // Get session
        const sessionResponse = await SessionReviewNetwork.getSession(
            sessionId
        );
        if (!sessionResponse.success) {
            store.dispatch(setLoadingSessionReview(false));
            return sessionResponse;
        }

        // Get messages
        const messageResponse = await SessionReviewNetwork.getMessages(
            sessionId
        );
        if (messageResponse.success) {
            sessionResponse.data.messages = messageResponse.data.data.messages;
        }

        // Return session only if it's ungraded
        const reviewId = sessionResponse.data?.feedback?.id;
        if (!reviewId) {
            store.dispatch(
                setCompletedReview(
                    this.buildResponseData({
                        session: sessionResponse.data
                    })
                )
            );
            store.dispatch(setLoadingSessionReview(false));
            return { success: true };
        }

        // Get session review
        const version = sessionResponse.data.feedback.rubric?.version ?? "4.0";

        // Get session feedback
        let feedbackData;
        switch (getRubricVersion(version)) {
            case RubricVersion.v5:
                const gradeResponse =
                    await SessionReviewNetwork.getSessionGrading(reviewId);
                if (gradeResponse.success) {
                    feedbackData = gradeResponse.data.data;
                }
                break;
            default:
                const legacyResponse =
                    await SessionReviewNetwork.legacyGetSessionReview(reviewId);
                if (legacyResponse.success) {
                    feedbackData = legacyResponse.data;
                }
        }
        if (!feedbackData) {
            store.dispatch(
                setCompletedReview(
                    this.buildResponseData({
                        session: sessionResponse.data
                    })
                )
            );
            store.dispatch(setLoadingSessionReview(false));
            return { success: true };
        }

        // Get grader data
        let userData;
        if (feedbackData.tqm_token) {
            const userResponse = await getUserAdminDetails(
                feedbackData.tqm_token
            );
            if (userResponse.success) {
                userData = userResponse.data;
            }
        }

        const rubricHelper = new Rubric(version);
        store.dispatch(
            setCompletedReview(
                this.buildResponseData({
                    review: feedbackData,
                    session: sessionResponse.data,
                    version,
                    rubric: rubricHelper.getFullRubric(feedbackData),
                    tags: rubricHelper.getTags(feedbackData),
                    feedback: rubricHelper.getFeedback(feedbackData),
                    gradedBy: userData
                })
            )
        );
        store.dispatch(setLoadingSessionReview(false));
        return { success: true };
    },

    async submitSessionReview(
        payload: SubmitScorePayload
    ): Promise<Response | null> {
        const current = store.getState().sessionReview.sessionForReview;
        if (current && current.id) {
            store.dispatch(setSubmittingSessionReview(true));
            const response = await SessionReviewNetwork.submitSessionReview(
                current.id,
                payload
            );
            if (response.success) {
                store.dispatch(setSessionForReview(null));
            }
            store.dispatch(setSubmittingSessionReview(false));
            return response;
        }
        return null;
    }
};

export default SessionReview;

export function getSessionReviewInfo() {
    const current = store.getState().sessionReview.sessionForReview;
    return {
        sessionReviewId: current?.id?.toString() ?? ""
    };
}

export function sessionReviewInProgress() {
    return Boolean(store.getState().sessionReview.sessionForReview?.id);
}

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const eraser: BaseCommand = {
    async execute(context: ZwibblerContext, lineWidth) {
        context.useBrushTool({
            strokeStyle: "erase",
            layer: "eraser",
            lineWidth
        });
    }
};

export default eraser;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";
import { DefaultProps } from "../types/Defaults";
import { getLineDashStyle } from "../helpers/lineDashStyle";

export const resetDefaults: BaseCommand = {
    execute(context: ZwibblerContext) {
        context.setColour(DefaultProps.fillColor, true);
        context.setColour(DefaultProps.strokeColor, false);
        context.setToolProperty(
            "dashes",
            getLineDashStyle(DefaultProps.lineStyle, DefaultProps.strokeWidth)
        );
        context.setToolProperty("lineWidth", DefaultProps.strokeWidth);
        context.setOpacity(DefaultProps.fillOpacity, true);
    }
};

export default resetDefaults;

import { RubricQuestion, RubricSection } from "shared/types/Rubric";
import { SessionTag } from "shared/types/SessionTag";
import { SessionReviewFeedback } from "shared/types/SessionReview";

import { SessionReviewFeedbackDefaults } from "shared/types/SessionReview";

export enum RubricVersion {
    v5 = "5.0",
    v4 = "4.0"
}

const LATEST = RubricVersion.v5;

export function getRubricVersion(version: string) {
    if (/^5\..+/g.test(version)) {
        return RubricVersion.v5;
    }
    return RubricVersion.v4;
}

export class Rubric {
    helper: RubricInterface;

    constructor(version?: string) {
        switch (getRubricVersion(version ?? LATEST)) {
            case RubricVersion.v5:
                this.helper = RubricV5;
                return;
        }
        this.helper = RubricV4;
    }
    getFullRubric(rubricData: any) {
        return this.helper.getFullRubric(rubricData);
    }
    getRubric(
        rubricData: Array<RubricSection>,
        feedback: SessionReviewFeedback
    ) {
        return this.helper.getRubric(rubricData, feedback);
    }
    getFeedback(reviewData: any) {
        return this.helper.getFeedback(reviewData);
    }
    getTags(reviewData: any) {
        return this.helper.getTags(reviewData);
    }
}

/*
 * Version handlers
 * ----------------------------
 */

interface RubricInterface {
    getRubric: (
        rubric: Array<RubricSection>,
        feedback: SessionReviewFeedback
    ) => Array<RubricSection>;
    getFeedback: (reviewData: any) => SessionReviewFeedback;
    getFullRubric: (rubricData: any) => Array<RubricSection>;
    getTags: (reviewData: any) => Array<SessionTag>;
}

const RubricV5: RubricInterface = {
    getRubric(rubric: Array<RubricSection>, feedback: SessionReviewFeedback) {
        const skipGrading = Object.values(feedback.tagKey)
            .flat()
            .some((tag) => tag.skip_grading);

        if (!feedback.startedAsking || skipGrading) {
            return [];
        }

        return rubric.filter(
            (pillars) =>
                !pillars.skip_unless_instruction_required ||
                feedback.instructionRequired
        );
    },
    getFeedback(feedback: any) {
        const tags = feedback.rubric.tags.filter((tag: any) => tag.selected);

        const strandCommentKey: { [key: number]: string } = {};
        const answerKey: { [key: number]: number } = {};
        let cannedComments: Array<number> = [];

        feedback.rubric.pillars
            .map((pillar: any) => pillar.questions)
            .flat()
            .forEach((question: any) => {
                const comments = question.canned_comments
                    .filter((comment: any) => comment.selected)
                    .map((comment: any) => comment.id);
                cannedComments = cannedComments.concat(comments);
                const answer = question.options.find(
                    (option: any) => option.selected
                );
                if (answer) {
                    answerKey[question.id] = answer.id;
                }

                if (question.comment)
                    strandCommentKey[question.id] = question.comment;
                else if (comments.length) {
                    strandCommentKey[question.id] = "";
                }
            });

        return {
            startedAsking:
                feedback.started_explaining ??
                SessionReviewFeedbackDefaults.startedAsking,
            generalFeedback:
                feedback.general_feedback ??
                SessionReviewFeedbackDefaults.generalFeedback,
            instructionRequired:
                feedback.instruction_required ??
                SessionReviewFeedbackDefaults.instructionRequired,
            answerKey,
            tagKey: { "": tags },
            ignore: feedback.ignore,
            strandCommentKey,
            cannedComments
        };
    },
    getFullRubric(rubricData: any) {
        return rubricData.rubric.pillars;
    },
    getTags(reviewData: any) {
        return reviewData.rubric.tags.filter((tag: any) => tag.selected);
    }
};

const RubricV4: RubricInterface = {
    getRubric(rubric: any, feedback: SessionReviewFeedback) {
        if (!feedback.startedAsking || feedback.ignore) {
            return [];
        }

        function buildQuestionPath(
            question: RubricQuestion,
            sectionQuestions: Array<RubricQuestion>,
            answers: { [key: number]: number }
        ) {
            let questions = [question];
            if (answers[question.id]) {
                const answer = question.options.find(
                    (o) => o.id === answers[question.id]
                );
                if (answer?.next_question_id) {
                    const nextQuestion = sectionQuestions.find(
                        (q) => q.id === answer.next_question_id
                    );
                    if (nextQuestion) {
                        const nextQuestions = buildQuestionPath(
                            nextQuestion,
                            sectionQuestions,
                            answers
                        );
                        questions = questions.concat(nextQuestions);
                    }
                }
            }
            return questions;
        }
        return rubric.map((section: RubricSection) => {
            return {
                ...section,
                questions: buildQuestionPath(
                    section.questions[0],
                    section.questions,
                    feedback.answerKey
                )
            };
        });
    },
    getFeedback(reviewData: any) {
        const rubric = reviewData.rubric.sections ?? [];
        const tags = reviewData.tags;
        const feedback = reviewData;

        const answerIds = feedback.values.map(
            (answer: any) => answer.rubric_question_option_id
        );
        const answerKey: { [key: number]: number } = {};
        rubric.forEach((section: RubricSection) => {
            section.questions.forEach((question) => {
                const answer = question.options.find((option) =>
                    answerIds.includes(option.id)
                );
                if (answer) {
                    answerKey[question.id] = answer.id;
                }
            });
        });

        return {
            ...SessionReviewFeedbackDefaults,
            startedAsking:
                feedback.started_explaining ??
                SessionReviewFeedbackDefaults.startedAsking,
            generalFeedback:
                feedback.general_feedback ??
                SessionReviewFeedbackDefaults.generalFeedback,
            answerKey,
            tagKey: { "": tags },
            ignore: feedback.ignore,
            transferable_feedback: feedback.transferable_feedback
        };
    },
    getFullRubric(rubricData: any) {
        return rubricData.rubric.sections;
    },
    getTags(reviewData: any) {
        return reviewData.tags;
    }
};

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

const presentCurrentPageNow: BaseCommand = {
    execute(context: ZwibblerContext) {
        const fromNumber = context.getCurrentPage();
        const toNumber = 0;
        context.movePage(fromNumber, toNumber);
        context.setCurrentPage(toNumber);
    }
};

export default presentCurrentPageNow;

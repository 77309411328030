import { EnvironmentHelper, UsersnapHelper } from "@yups/utils";
import { format } from "date-fns";
import { Config } from "./Config";
import { getSessionReviewInfo } from "models/SessionReview";
import { User, getUserInfo } from "models/User";
import { getShiftInfo } from "models/Shift";
import { getSessionInfo } from "models/Session";
import { EventLog } from "events/EventLog";
import { Event } from "events/Event";

const Usersnap = new UsersnapHelper({
    globalApiKey: Config.usersnapApiKey,
    projectApiKey: Config.usersnapTutorApiKey,
    callbacks: {
        customData: () => {
            return {
                ...getUserInfo(),
                localTimestamp: format(new Date(), "MM/dd/yyyy hh:mma"),
                timestamp: new Date().toLocaleString("en-US", {
                    timeZone: "America/Los_Angeles"
                }),
                ...getSessionReviewInfo(),
                ...getShiftInfo(),
                environment: EnvironmentHelper.currentEnvironment,
                build: Config.buildId,
                commit: Config.commitRef,
                ...getSessionInfo(),
                recentEvents: EventLog.recentLogs
            };
        },
        onClose: Event.dispatcher("usersnap_close")
    }
});

export function openUsersnapForm() {
    if (User.isApplicant()) {
        Usersnap.triggerEvent("on_open_applicant_usersnap");
    } else {
        Usersnap.triggerEvent("on_open_tutor_usersnap");
    }
}

export default Usersnap;

import { createSlice } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import store from "store";
import { StoreKey } from "store/StoreKey";
import { v4 as uuidv4 } from "uuid";

const storePrefix = StoreKey.device;
const storage = new Storage(storePrefix, clearDevice);
function clearDevice(): void {
    store.dispatch(deviceSlice.actions.reset());
}

type DeviceType = {
    current: {
        uuid?: string;
    };
};

const deviceSlice = createSlice({
    name: "device",
    initialState: { current: {} } as DeviceType,
    reducers: {
        init(state) {
            state.current = storage.get() ?? {};
            if (!state.current.uuid) {
                state.current.uuid = uuidv4();
                updateCache(state);
            }
        },
        reset(state) {
            state.current = {};
            updateCache(state);
        }
    }
});

export default deviceSlice.reducer;

export const { init } = deviceSlice.actions;

function updateCache(state: DeviceType) {
    // Prevent UUID from changing. It should only reset when
    // the user logs out. Otherwise, if user A logs in and out
    // and then user B logs in on the same device, Mixpanel will
    // associate user B's events with user A. The downside is if
    // user A logs in, out, and back in, they'll increase MTU
    // as the device UUID determines if a user is "new" in Mixpanel.
    if (state.current.uuid && !storage.get()?.uuid) {
        storage.set(state.current);
    } else if (!state.current.uuid) {
        storage.remove();
    }
}

import { Subtopic } from "./Subtopic";
import { Tutor } from "./Tutor";

export enum OnboardingStatus {
    essential_knowledge = "essential_knowledge",
    background = "background",
    failed_teaching_exam = "failed_teaching_exam",
    dropped_out = "dropped_out",
    fired = "fired",
    subject_exam = "subject_exam",
    teaching_exam = "teaching_exam",
    demo_sessions = "demo_sessions",
    demo_sessions_scheduled = "demo_sessions_scheduled",
    grading_demo_sessions = "grading_demo_sessions",
    demo_sessions_failed = "demo_sessions_failed",
    waiting_for_offer = "waiting_for_offer",
    contract = "contract",
    schedule = "schedule",
    tutor_bio = "tutor_bio",
    waiting_for_schedule = "waiting_for_schedule",
    full_time_tutor = "full_time_tutor"
}

export type OnboardingBackgroundData = {
    degrees: Array<string>;
    interests: Array<string>;
    schools: Array<string>;
    subjects: Array<string>;
    years: Array<string>;
};

export type OnboardingBackgroundPayload = {
    degree: string;
    major: string;
    school: string;
    years_teaching: string;
    years_tutoring: string;
    interests: string;
    gender: string;
    resume_url: string;
    date_of_birth: Date;
};

export type OnboardingSubject = {
    id: number;
    name: string;
    blurb: string;
    available: boolean;
    identifier: string;
    exam_pass_score: number;
    exam_total_questions: number;
    exam_duration_minutes: number;
    disable_onboarding: boolean;
    subtopics: Array<Subtopic>;
};

export type OnboardingStatusInfo = {
    id: number;
    name: OnboardingStatus;
    group: string;
    label: string;
};

export enum Resources {
    introVideo = "tutor_onboarding_intro_video",
    subjectExam = "example_subject_exam"
}

export type OnboardingSettings = {
    subjects: Array<OnboardingSubject>;
    onboarding_statuses: Array<OnboardingStatusInfo>;
    resources: {
        [key in Resources]: {
            url: string;
            label: string;
        };
    };
    teaching_exam_url: string;
    written_exam_turnaround_time: string;
    tutor_support_email: string;
};

export const DefaultOnboardingSettings: OnboardingSettings = {
    subjects: [],
    onboarding_statuses: [],
    resources: {
        [Resources.introVideo]: { url: "", label: "" },
        [Resources.subjectExam]: { url: "", label: "" }
    },
    teaching_exam_url: "",
    written_exam_turnaround_time: "",
    tutor_support_email: ""
};

export type OnboardingExamQuestion = {
    id: number;
    image_url: string;
};

export type OnboardingExam = {
    id: number;
    started_at: number;
    ended_at: number;
    questions_map: Array<number>;
    subject: OnboardingSubject;
    passed: boolean;
    exam_question_responses: number;
    should_end_in_seconds: number;
    finished: boolean;
};

export type OnboardingExamAnswerPayload = {
    question_id: number;
    question_type: string;
    text: string;
    exam_id?: number;
};

export enum OnboardingBackgroundCheckStatus {
    notStarted = "not-started",
    invited = "invited",
    pending = "pending",
    needsReview = "needs-review",
    passed = "passed"
}

export type OnboardingBioPayload = {
    life_goal: string;
    dream_job: string;
    fun_fact: string;
    why_love_math: string;
    fav_subject: string;
    weekend_activity: string;
    uni_major: string;
    fav_food: string;
    fav_ice_cream: string;
    fav_movie: string;
    fav_animal: string;
    fav_game: string;
};

export type OnboardingExamReviewResponse = {
    question: number;
    url: string;
    correct_answer: string;
    applicant_answer: string;
    is_correct: boolean;
    subtopic: string;
    difficulty: number;
};

export type OnboardingExamReview = {
    id: number;
    score: number;
    started_at: number;
    ended_at: number;
    questions_map: Array<number>;
    subject: Subtopic & {
        exam_pass_score: number;
        exam_total_questions: number;
    };
    passed: boolean;
    exam_question_responses: number;
    applicant: Tutor;
    applicant_response: Array<OnboardingExamReviewResponse>;
};

import WebService from "helpers/WebService";
import { SubmitScorePayload } from "shared/types/SessionReview";
import SessionReview from "./SessionReview";

enum ErrorMessages {
    errorReview = "Failed to load session to review. Please try again.",
    errorMessages = "Failed to load the session. Please try again.",
    errorReviewSettings = "Failed to load the session. Please try again.",
    errorWhiteboardCaptures = "Failed to load the whiteboard.",
    errorNextSession = "There are no new sessions to review.",
    errorSubmitReview = "Failed to submit review. Please try again."
}

const SessionReviewNetwork = {
    async getNextSessionReview() {
        WebService.setRetries(2);
        const response = await WebService.nextSessionReview();
        response.data = response.data.data;
        if (!response.success || !response.data.id) {
            response.success = false;
            SessionReview.setError(ErrorMessages.errorNextSession);
        }
        return response;
    },

    async legacyGetSessionReview(sessionReviewId: number) {
        const response = await WebService.legacyGetSessionReview(
            sessionReviewId
        );
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorReview);
        }
        return response;
    },

    async getSessionGrading(sessionReviewId: number) {
        const response = await WebService.getSessionGrading(sessionReviewId);
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorReview);
        }
        return response;
    },

    async getReviewSettings() {
        const response = await WebService.getTutorRubric();
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorReviewSettings);
        }
        return response;
    },

    async getWhiteboardCaptures(sessionId: number) {
        const response = await WebService.getWhiteboardCaptures(sessionId);
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorWhiteboardCaptures);
        }
        return response;
    },

    async getMessages(sessionId: number) {
        const response = await WebService.sessionGetNewMessages(sessionId, 0);
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorMessages);
        }
        return response;
    },

    async getSession(sessionId: number) {
        const response = await WebService.sessionGet(sessionId);
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorMessages);
        }
        return response;
    },

    async getSessionTags(sessionId: number) {
        const response = await WebService.sessionGet(sessionId);
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorMessages);
        }
        return response;
    },

    async submitSessionReview(sessionId: number, payload: SubmitScorePayload) {
        const response = await WebService.submitSessionReview(
            sessionId,
            payload
        );
        if (!response.success) {
            SessionReview.setError(ErrorMessages.errorSubmitReview);
        }
        return response;
    }
};

export default SessionReviewNetwork;

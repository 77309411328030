import { getUser } from "models/User";
import { currentSession } from "models/Session";
import MessageType, {
    createMessage,
    MessageAdditionalAttributes,
    MessageSender
} from "shared/components/ChatRoom/types/Message";
import WebService from "helpers/WebService";
import store from "store/index";
import { addMessagesV2, saveLatestMessagesStatus } from "store/Session";
import SessionNetwork from "./SessionNetwork";
import { Event } from "events/Event";
import Logger, { Event as LoggerEvent } from "helpers/Logger";

export class SessionMessages {
    static async sendText(text: string, isImage = false): Promise<void> {
        await this.sendMessage(text, isImage);
    }

    static async sendMessage(
        text: string,
        isImage = false,
        additionalAttributes?: MessageAdditionalAttributes
    ): Promise<void> {
        const current = currentSession();
        if (!current) return;
        const message: any = createMessage({
            message: text,
            isImage: isImage,
            additionalAttributes,
            senderToken: getUser()?.token,
            senderId: current.tutor.id,
            sessionId: current.id
        });
        store.dispatch(addMessagesV2([message]));

        WebService.setRetries(3);
        const response = await WebService.sessionSendMessage(message);
        if (response.success) {
            if (isImage) {
                Event.dispatch("session_tutor_image_sent", { url: message });
            } else {
                Event.dispatch("session_tutor_message_sent");
            }

            Logger.event(LoggerEvent.sessionMessageSent, "", {
                message,
                messageCounts: messageCounts()
            });

            // Backward compatibility: send message through Pusher too
            SessionNetwork.sendMessage(message);
        } else {
            store.dispatch(addMessagesV2([{ ...message, failedToSend: true }]));
        }
    }

    static async getNewMessages() {
        const current = currentSession();
        if (!current) return;
        const latestMessageId = store.getState().session.latestMessageId;
        // TODO: fix `makeRequest` so it does not return reponse.data.data...
        const { data: response } = await WebService.sessionGetNewMessages(
            current.id,
            latestMessageId
        );
        if (!response.success) return;
        store.dispatch(
            addMessagesV2(
                response.data.messages.concat(response.data.reactions ?? [])
            )
        );
        if (
            hasMessagesFromStudent(response.data.messages) &&
            latestMessageId > 0
        ) {
            Event.dispatch("session_student_message_received");

            response.data.messages.forEach((message: MessageType) => {
                let value =
                    new Date().getTime() -
                    new Date(Number(message.sent_at) * 1000).getTime();
                Logger.event(LoggerEvent.sessionMessageReceived, "", {
                    message,
                    monitor: {
                        type: "time",
                        value: value,
                        tags: [
                            "event:message_received",
                            "from:student",
                            "to:tutor"
                        ]
                    },
                    messageCounts: messageCounts()
                });
            });
        }
    }

    static async sendLatestMessageId() {
        const current = currentSession();
        if (!current) return;
        const latestMessageId = store.getState().session.latestMessageId;
        await WebService.sessionSendLatestMessageId(
            current.id,
            latestMessageId
        );
    }

    static async getNewDeliveries() {
        const current = currentSession();
        if (!current) return;
        const response = await WebService.getNewDeliveries(current.id);
        if (!response.success) return;
        store.dispatch(saveLatestMessagesStatus(response.data));
    }

    static async retryMessageSend(message: MessageType) {
        let msg = Object.assign({}, message);
        msg.failedToSend = false;
        store.dispatch(addMessagesV2([msg]));

        WebService.setRetries(3);
        const response = await WebService.sessionSendMessage(message);
        if (response.success) {
            SessionNetwork.sendMessage(message);
        } else {
            let msg = Object.assign({}, message);
            msg.failedToSend = true;
            store.dispatch(addMessagesV2([msg]));
        }
    }
}

function hasMessagesFromStudent(messages: MessageType[]) {
    return messages.some(
        (message) => message.sent_from === MessageSender.student
    );
}

function messageCounts() {
    const messages = store.getState().session.current?.messages || [];
    return {
        sent: messages.filter(
            (message) => message.sent_from === MessageSender.tutor
        ).length,
        received: messages.filter(
            (message) => message.sent_to === MessageSender.tutor
        ).length
    };
}

import { NetworkHelper } from "@yups/utils";

const WebPushManager = {
    async getSubscription() {
        if ("serviceWorker" in navigator) {
            const registration = await navigator.serviceWorker.ready;

            const subscription =
                await registration.pushManager.getSubscription();
            if (subscription === null) {
                await this.subscribeUser();
            } else {
                await this.savePushSubscription(subscription);
            }
        }
    },

    async subscribeUser() {
        if ("serviceWorker" in navigator) {
            try {
                const registration = await navigator.serviceWorker.ready;
                const publicKeyDecodedString =
                    process.env?.REACT_APP_WEB_PUSH_PUBLIC_KEY ?? "[]";
                const publicKey: Array<number> = JSON.parse(
                    publicKeyDecodedString
                );
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: new Uint8Array(publicKey)
                });
                this.savePushSubscription(subscription);
            } catch (error) {
                if (Notification.permission === "denied") {
                    console.warn("Permission for notifications was denied");
                } else {
                    console.error("Unable to subscribe to push", error);
                }
            }
        }
    },
    async savePushSubscription(subscription: PushSubscription) {
        try {
            const response = await NetworkHelper.post({
                endPoint: "/api/users/save_push_subscription",
                params: { push_subscription: subscription }
            });

            if (response.success !== true) {
                console.error("Failed to save push subscription", response);
            }
        } catch (error) {
            console.error(error);
        }
    }
};

export default WebPushManager;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const deleteSelection: BaseCommand = {
    execute(context: ZwibblerContext) {
        context.deleteSelection();
        LogAction(context, "delete_selection");
    }
};

export default deleteSelection;

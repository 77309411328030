export enum DebugLog {
    Pusher = 1 << 0,
    Observers = 1 << 1,
    APIRequests = 1 << 2,
    Controllers = 1 << 3
}

let debugLogs = 0;

const Debug = {
    get isDevelopment() {
        return process.env["NODE_ENV"] === "development";
    },

    set(flags: number) {
        debugLogs |= flags;
    },

    unset(flags: number) {
        debugLogs &= ~flags;
    },

    get(debugLog: DebugLog) {
        return debugLogs & debugLog;
    },

    reset() {
        debugLogs = 0;
    }
};

export default Debug;

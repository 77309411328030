import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const zoomIn: BaseCommand = {
    async execute(context: ZwibblerContext) {
        context.zoomIn();
        LogAction(context, WhiteboardAction.zoomIn);
    }
};

export default zoomIn;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";
import { Shape } from "../types/Shape";

export const shape: BaseCommand = {
    execute(context: ZwibblerContext, shape: Shape) {
        if (shape === Shape.circle) {
            context.useCircleTool();
        } else if (shape === Shape.rectangle) {
            context.useRectangleTool();
        } else if (shape === Shape.triangle) {
            context.usePolygonTool(3, 0);
        } else if (shape === Shape.rightTriangle) {
            context.useShapeTool(
                "PathNode",
                {
                    //@ts-ignore
                    commands: Zwibbler.commandsFromSVGPath(
                        "M0,0l100,100h-100v-100z"
                    )
                },
                100,
                100
            );
        }
    }
};

export default shape;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Activity } from "shared/components/ChatRoom/types/Activity";
import {
    CoveredTopic,
    DefaultCoveredTopic
} from "shared/types/SessionFeedback";
import { GalleryLists } from "shared/types/GalleryLists";
import { Config } from "helpers/Config";
import { WhiteboardCapture } from "shared/types/WhiteboardCapture";

type UIStateType = {
    showUsersnapButton: boolean;
    wantsToEndSession: boolean;
    processingSession: boolean;
    studentActivity: Activity | null;
    processingShift: boolean;
    matchSecondsLeft: number;
    loadingSessionReview: boolean;
    submittingSessionReview: boolean;
    processingSignIn: boolean;
    forgotPasswordSuccess: boolean;
    resetPasswordSuccess: boolean;
    connection: {
        pusherError: string;
        spotty: boolean;
        disconnected: boolean;
    };

    // Dashboard
    showSessionReviewPrompt: boolean;

    // Onboarding
    onboardingStep: number;
    onboardingRedirectSecondsLeft: number;
    onboardingShowVideo: boolean;
    onboardingLoading: boolean;
    onboardingShowSampleQuestions: boolean;
    onboardingShowExamStartPrompt: boolean;
    onboardingExamAnswer: string;
    onboardingExamSecondsLeft: number;
    onboardingShowBackgroundCheck: boolean;
    onboardingSubmittingBackgroundCheck: boolean;

    // Post-session
    submittingSessionFeedback: boolean;
    coveredTopics: Array<CoveredTopic>;
    topicNotCovered: boolean;
    alternativeTopicCovered: string;
    engagementScore: string;

    // Session
    session: {
        warning_text: string;
        seconds_left_for_review: number;
        seconds_left_for_feedback: number;
        sending_image: boolean;
        show_math_typing: boolean;
        show_quick_responses: boolean;
        show_achievements: boolean;
        image_list: GalleryLists | null;
    };

    // Chat
    chatInput: string;

    // Whiteboard player
    whiteboardPlayerPlaying: boolean;
    whiteboardPlayerTimestamp: number;
    whiteboardPlayerImage: string;
    whiteboardPlayerLoading: boolean;
    whiteboardCaptures: Array<WhiteboardCapture>;
};

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        showUsersnapButton: true,
        wantsToEndSession: false,
        processingSession: false,
        studentActivity: null,
        processingShift: false,
        matchSecondsLeft: 0,
        loadingSessionReview: false,
        submittingSessionReview: false,
        processingSignIn: false,
        forgotPasswordSuccess: false,
        resetPasswordSuccess: false,

        // Dashboard
        showSessionReviewPrompt: false,

        // Connection
        connection: {
            pusherError: "",
            spotty: false,
            disconnected: false
        },

        // Onboarding
        onboardingStep: 0,
        onboardingRedirectSecondsLeft: Config.onboardingRedirectInSeconds,
        onboardingShowVideo: false,
        onboardingLoading: false,
        onboardingShowSampleQuestions: false,
        onboardingShowExamStartPrompt: false,
        onboardingExamAnswer: "",
        onboardingExamSecondsLeft: 0,
        onboardingShowBackgroundCheck: false,
        onboardingSubmittingBackgroundCheck: false,

        // Post-session
        submittingSessionFeedback: false,
        engagementScore: "",
        coveredTopics: [DefaultCoveredTopic],
        topicNotCovered: false,
        alternativeTopicCovered: "",

        // Session
        session: {
            warning_text: "",
            seconds_left_for_review: Config.sessionReviewTimeoutInSeconds,
            seconds_left_for_feedback: Config.sessionFeedbackTimeoutInSeconds,
            sending_image: false,
            show_math_typing: false,
            show_quick_responses: false,
            show_achievements: false,
            image_list: null
        },

        // Chat
        chatInput: "",

        // Whiteboard player
        whiteboardPlayerPlaying: false,
        whiteboardPlayerLoading: false,
        whiteboardPlayerTimestamp: 0,
        whiteboardPlayerImage: "",
        whiteboardCaptures: []
    } as UIStateType,
    reducers: {
        setShowUsersnapButton: (state, action: PayloadAction<boolean>) => {
            state.showUsersnapButton = action.payload;
        },
        setWantsToEndSession: (state, action: PayloadAction<boolean>) => {
            state.wantsToEndSession = action.payload;
        },
        setProcessingSession: (state, action: PayloadAction<boolean>) => {
            state.processingSession = action.payload;
        },
        setStudentActivity: (state, action: PayloadAction<Activity | null>) => {
            state.studentActivity = action.payload;
        },
        setProcessingShift: (state, action: PayloadAction<boolean>) => {
            state.processingShift = action.payload;
        },
        setMatchSecondsLeft: (state, action: PayloadAction<number>) => {
            state.matchSecondsLeft = action.payload;
        },
        setLoadingSessionReview: (state, action: PayloadAction<boolean>) => {
            state.loadingSessionReview = action.payload;
        },
        setSubmittingSessionReview: (state, action: PayloadAction<boolean>) => {
            state.submittingSessionReview = action.payload;
        },
        setProcessingSignIn: (state, action: PayloadAction<boolean>) => {
            state.processingSignIn = action.payload;
        },
        setForgotPasswordSuccess: (state, action: PayloadAction<boolean>) => {
            state.forgotPasswordSuccess = action.payload;
        },
        setResetPasswordSuccess: (state, action: PayloadAction<boolean>) => {
            state.resetPasswordSuccess = action.payload;
        },

        // Dashboard
        setShowSessionReviewPrompt: (state, action: PayloadAction<boolean>) => {
            state.showSessionReviewPrompt = action.payload;
        },

        // Connection
        setPusherError: (state, action: PayloadAction<string>) => {
            state.connection.pusherError = action.payload;
        },
        setConnectionSpotty: (state, action: PayloadAction<boolean>) => {
            state.connection.spotty = action.payload;
        },
        setDisconnected: (state, action: PayloadAction<boolean>) => {
            state.connection.disconnected = action.payload;
        },

        // Onboarding
        setOnboardingStep: (state, action: PayloadAction<number>) => {
            state.onboardingStep = action.payload;
        },
        setOnboardingRedirectSecondsLeft: (
            state,
            action: PayloadAction<number>
        ) => {
            state.onboardingRedirectSecondsLeft = action.payload;
        },
        setOnboardingShowVideo: (state, action: PayloadAction<boolean>) => {
            state.onboardingShowVideo = action.payload;
        },
        setOnboardingLoading: (state, action: PayloadAction<boolean>) => {
            state.onboardingLoading = action.payload;
        },
        setOnboardingShowSampleQuestions: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.onboardingShowSampleQuestions = action.payload;
        },
        setOnboardingShowExamStartPrompt: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.onboardingShowExamStartPrompt = action.payload;
        },
        setOnboardingExamAnswer: (state, action: PayloadAction<string>) => {
            state.onboardingExamAnswer = action.payload;
        },
        setOnboardingExamSecondsLeft: (
            state,
            action: PayloadAction<number>
        ) => {
            state.onboardingExamSecondsLeft = action.payload;
        },
        setOnboardingShowBackgroundCheck: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.onboardingShowBackgroundCheck = action.payload;
        },
        setOnboardingSubmittingBackgroundCheck: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.onboardingSubmittingBackgroundCheck = action.payload;
        },

        // Post-session
        setSubmittingSessionFeedback: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.submittingSessionFeedback = action.payload;
        },
        addCoveredTopic: (state) => {
            state.coveredTopics.push(DefaultCoveredTopic);
        },
        updateCoveredTopics: (
            state,
            action: PayloadAction<{ coveredTopic: CoveredTopic; index: number }>
        ) => {
            state.coveredTopics[action.payload.index] =
                action.payload.coveredTopic;
        },
        deleteCoveredTopic: (state, action: PayloadAction<number>) => {
            state.coveredTopics = state.coveredTopics.filter(
                (_, i) => i !== action.payload
            );
        },
        toggleTopicNotCovered: (state) => {
            state.topicNotCovered = !state.topicNotCovered;
        },
        setAlternativeTopicCovered: (state, action: PayloadAction<string>) => {
            state.alternativeTopicCovered = action.payload;
        },
        setEngagementScore: (state, action: PayloadAction<string>) => {
            state.engagementScore = action.payload;
        },
        resetSessionFeedback: (state) => {
            state.submittingSessionFeedback = false;
            state.coveredTopics = [DefaultCoveredTopic];
            state.topicNotCovered = false;
            state.alternativeTopicCovered = "";
            state.engagementScore = "";
        },

        // Session
        setSessionWarningText: (state, action: PayloadAction<string>) => {
            state.session.warning_text = action.payload;
        },
        setSecondsLeftForReview: (state, action: PayloadAction<number>) => {
            state.session.seconds_left_for_review = action.payload;
        },
        setSecondsLeftForFeedback: (state, action: PayloadAction<number>) => {
            state.session.seconds_left_for_feedback = action.payload;
        },
        setSendingImage: (state, action: PayloadAction<boolean>) => {
            state.session.sending_image = action.payload;
        },
        toggleMathTyping: (state) => {
            state.session.show_math_typing = !state.session.show_math_typing;
        },
        setShowQuickResponses: (state, action: PayloadAction<boolean>) => {
            state.session.show_quick_responses = action.payload;
        },
        setShowAchievements: (state, action: PayloadAction<boolean>) => {
            state.session.show_achievements = action.payload;
        },
        setImageList: (state, action: PayloadAction<GalleryLists | null>) => {
            state.session.image_list = action.payload;
        },
        setChatInput: (state, action: PayloadAction<string>) => {
            state.chatInput = action.payload;
        },
        appendChatInput: (state, action: PayloadAction<string>) => {
            state.chatInput += action.payload;
        },

        // Whiteboard player
        setWhiteboardPlayerPlaying: (state, action: PayloadAction<boolean>) => {
            state.whiteboardPlayerPlaying = action.payload;
        },
        setWhiteboardPlayerLoading: (state, action: PayloadAction<boolean>) => {
            state.whiteboardPlayerLoading = action.payload;
        },
        setWhiteboardPlayerTimestamp: (
            state,
            action: PayloadAction<number>
        ) => {
            state.whiteboardPlayerTimestamp = action.payload;
        },
        setWhiteboardPlayerImage: (state, action: PayloadAction<string>) => {
            state.whiteboardPlayerImage = action.payload;
        },
        setWhiteboardCaptures: (
            state,
            action: PayloadAction<Array<WhiteboardCapture>>
        ) => {
            state.whiteboardCaptures = action.payload;
        }
    }
});

export default uiSlice.reducer;

export const {
    setShowUsersnapButton,
    setWantsToEndSession,
    setProcessingSession,
    setStudentActivity,
    setProcessingShift,
    setMatchSecondsLeft,
    setLoadingSessionReview,
    setSubmittingSessionReview,
    setProcessingSignIn,
    setForgotPasswordSuccess,
    setResetPasswordSuccess,

    // Connection
    setPusherError,
    setConnectionSpotty,
    setDisconnected,

    // Dashboard
    setShowSessionReviewPrompt,

    // Onboarding
    setOnboardingStep,
    setOnboardingRedirectSecondsLeft,
    setOnboardingShowVideo,
    setOnboardingLoading,
    setOnboardingShowSampleQuestions,
    setOnboardingShowExamStartPrompt,
    setOnboardingExamAnswer,
    setOnboardingExamSecondsLeft,
    setOnboardingShowBackgroundCheck,
    setOnboardingSubmittingBackgroundCheck,

    // Post-session
    setSubmittingSessionFeedback,
    addCoveredTopic,
    deleteCoveredTopic,
    updateCoveredTopics,
    toggleTopicNotCovered,
    setAlternativeTopicCovered,
    setEngagementScore,
    resetSessionFeedback,

    // Session
    setSessionWarningText,
    setSecondsLeftForReview,
    setSecondsLeftForFeedback,
    setSendingImage,
    toggleMathTyping,
    setShowQuickResponses,
    setShowAchievements,
    setImageList,

    // Chat
    setChatInput,
    appendChatInput,

    // Whiteboard player
    setWhiteboardPlayerPlaying,
    setWhiteboardPlayerTimestamp,
    setWhiteboardPlayerImage,
    setWhiteboardPlayerLoading,
    setWhiteboardCaptures
} = uiSlice.actions;

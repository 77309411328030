import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const strokeOpacity: BaseCommand = {
    execute(
        context: ZwibblerContext,
        props: {
            tool: WhiteboardAction;
            opacity: number;
        }
    ) {
        context.setOpacity(props.opacity, false);
        LogAction(context, props.tool, {
            transparency_changed: props.opacity * 10
        });
    }
};

export default strokeOpacity;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand } from "./BaseCommand";

export const polygon: BaseCommand = {
    execute(context: ZwibblerContext, sides: number) {
        const rotation = sides % 2 ? 0 : Math.PI / sides;
        context.usePolygonTool(sides, rotation);
    }
};

export default polygon;

import learningObjectives from "shared/data/learning-objectives.json";

export type CoveredTopic = {
    topic: string;
    mastery: number;
    subtopic: string;
    learning_objective: string;
    gap_area?: string;
};
export const DefaultCoveredTopic = {
    topic: "",
    mastery: 0,
    subtopic: "",
    learning_objective: ""
};

export function getLearningObjectivesMap() {
    return learningObjectives.reduce<
        Record<string, { area: string; topic: string }>
    >((dict, topic) => {
        topic.areas.forEach((area) =>
            area.objectives.forEach((objective) => {
                dict[objective] = {
                    area: area.area,
                    topic: topic.subtopic
                };
            })
        );
        return dict;
    }, {});
}

export function getLearningObjectiveData(learning_objective: string) {
    const map = getLearningObjectivesMap();
    return {
        topic: map[learning_objective]?.topic ?? "",
        subtopic: map[learning_objective]?.area ?? "",
        learning_objective
    };
}
export function isValidLearningObjective(learning_objective: string) {
    return Boolean(getLearningObjectivesMap()[learning_objective]);
}
export enum SessionFeedbackParam {
    Subtopic = "tutor_subtopic_id",
    Comment = "tutor_comment_for_student",
    EngagementScore = "student_engagement_score"
}

export type SessionFeedback = {
    [SessionFeedbackParam.Subtopic]?: number | null;
    [SessionFeedbackParam.Comment]: string;
    [SessionFeedbackParam.EngagementScore]?: string | null;
};

export const SessionFeedbackDefaults = {
    // TODO: hardcoded values are being used on backend.
    // remove when backend sets these by default
    [SessionFeedbackParam.Subtopic]: null,
    [SessionFeedbackParam.Comment]: "",
    [SessionFeedbackParam.EngagementScore]: null
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { RootStateOrAny } from "react-redux";
import {
    MessageType,
    dedupeMessages,
    successfullySent
} from "shared/components/ChatRoom/types/Message";
import { Session as SessionType } from "shared/types/Session";
import store from "store";
import { StoreKey } from "store/StoreKey";

const storePrefix = StoreKey.session;
const storage = new Storage(storePrefix, clearSession);
function clearSession(): void {
    store.dispatch(sessionSlice.actions.setCurrent(null));
}

type SessionStateType = {
    current: SessionType | null;
    latestMessageId: number;
    latestMessagesStatus: { [key: string]: number } | null;
    error: string;
};

const sessionSlice = createSlice({
    name: "session",
    initialState: {
        current: storage.get() ?? null,
        latestMessageId: 0,
        latestMessagesStatus: {},
        error: ""
    } as SessionStateType,
    reducers: {
        setCurrent: (state, action: PayloadAction<SessionType | null>) => {
            if (state.current && action.payload) {
                state.current = {
                    ...action.payload,
                    messages: state.current.messages // Leave setting messages to other reducers
                };
            } else {
                state.current = action.payload;
            }

            if (!state.current) {
                state.latestMessageId = 0;
                state.latestMessagesStatus = null;
            }
            updateCache(state);
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        addMessagesV2: (state, action: PayloadAction<Array<MessageType>>) => {
            if (state.current && action.payload.length) {
                state.current.messages = dedupeMessages([
                    ...state.current.messages,
                    ...action.payload
                ]);
                const savedMessages =
                    state.current.messages.filter(successfullySent);
                state.latestMessageId = Math.max(
                    ...savedMessages.map((m) => m.id ?? -1)
                );

                updateCache(state);
            }
        },
        saveLatestMessagesStatus: (
            state,
            action: PayloadAction<{ [key: string]: number }>
        ) => {
            state.latestMessagesStatus = {
                ...state.latestMessagesStatus,
                ...action.payload
            };
        },
        setMessages: (state, action: PayloadAction<Array<MessageType>>) => {
            if (state.current) {
                state.current.messages = dedupeMessages(action.payload);

                updateCache(state);
            }
        },
        init: (state) => {
            state.current = storage.get()?.current;
            state.latestMessageId = storage.get()?.latestMessageId;
            state.latestMessagesStatus = storage.get()?.latestMessagesStatus;
        }
    }
});

export default sessionSlice.reducer;

export const {
    setCurrent,
    setError,
    addMessagesV2,
    saveLatestMessagesStatus,
    setMessages,
    init
} = sessionSlice.actions;

// On web, platform is formatted as "Browser:OS", like "chrome:Windows 10"
// On mobile, platform is formatted as "OS" only, like "iOS"
// TODO: When moving to React Native, add the explicit `Platform.OS` to the session model.
export const selectIsWebStudent = (state: RootStateOrAny): boolean =>
    state.session.current?.platform.includes(":") ?? false;
export const selectIsMobileStudent = (state: RootStateOrAny): boolean =>
    !selectIsWebStudent(state);

export const isWebStudent = (): boolean => {
    const state = store.getState();
    return selectIsWebStudent(state);
};
export const isMobileStudent = (): boolean => {
    const state = store.getState();
    return selectIsMobileStudent(state);
};

function updateCache(state: SessionStateType) {
    if (state.current) {
        storage.set(state);
    } else {
        storage.remove();
    }
}

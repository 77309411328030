import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";
import { Format } from "../types/Format";

export const download: BaseCommand = {
    execute(context: ZwibblerContext, format: Format) {
        context.download(
            format === Format.yup ? "zwibbler3" : format,
            `Drawing.${format}`
        );
        LogAction(context, `download_${format}`);
    }
};

export default download;

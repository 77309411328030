import { LocalStorageHelper } from "@yups/utils";
import { StoreKey } from "store/StoreKey";
import Sentry from "helpers/Sentry";

export const StorageManager = {
    cleanupFunctions: {} as { [key: string]: Function },

    async clearCache() {
        await Promise.all(
            Object.values(StoreKey).map(async (key) => {
                await LocalStorageHelper.remove(key);
            })
        );
    },

    async restoreCache() {
        await Promise.all(
            Object.values(StoreKey).map(async (key) => {
                await LocalStorageHelper.get(key);
            })
        );
    },

    get(storePrefix: string) {
        const data = LocalStorageHelper.getSync(storePrefix);
        return data ? JSON.parse(data) : null;
    },

    set(storePrefix: string, data: object) {
        try {
            LocalStorageHelper.setSync(storePrefix, JSON.stringify(data));
        } catch (error) {
            Sentry.logError(error);

            // TODO: handle if localStorage limit is exceeded
        }
    },

    remove(storePrefix: string) {
        LocalStorageHelper.removeSync(storePrefix);
    },

    addCleanupMethod(storePrefix: string, onCleanup: Function) {
        this.cleanupFunctions[storePrefix] = onCleanup;
    },

    cleanup() {
        Object.values(this.cleanupFunctions).forEach((cleanup) => cleanup());
    }
};

export class Storage {
    storePrefix: string;
    constructor(storePrefix: string, onCleanup?: Function) {
        this.storePrefix = storePrefix;
        onCleanup && StorageManager.addCleanupMethod(storePrefix, onCleanup);
    }

    get() {
        return StorageManager.get(this.storePrefix);
    }

    set(data: object) {
        StorageManager.set(this.storePrefix, data);
    }

    remove() {
        StorageManager.remove(this.storePrefix);
    }
}

export default Storage;

import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";
import { getLineDashStyle } from "../helpers/lineDashStyle";

import { LineStyle } from "../types/LineStyle";
import { NodeType } from "../types/NodeType";

export const lineStyle: BaseCommand = {
    async execute(
        context: ZwibblerContext,
        props: {
            style: LineStyle;
            tool: string;
        }
    ) {
        const width = context.getPropertySummary().properties.lineWidth;
        context.setToolProperty("dashes", getLineDashStyle(props.style, width));

        // Only change lines and shapes
        context.getSelectedNodes().forEach((nodeId) => {
            if (context.getNodeType(nodeId) === NodeType.Path) {
                const width = context.getNodeProperty(nodeId, "lineWidth");
                context.setNodeProperty(
                    nodeId,
                    "dashes",
                    getLineDashStyle(props.style, width)
                );
            }
        });
        LogAction(context, props.tool, {
            line_style_changed: props.style
        });
    }
};

export default lineStyle;

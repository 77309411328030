import { WhiteboardAction } from "../types/WhiteboardAction";
import { BaseCommand } from "./BaseCommand";

import addPage from "./addPage";
import removePage from "./removePage";
import selectPage from "./selectPage";
import movePage from "./movePage";
import uploadImage from "./uploadImage";
import clear from "./clear";
import send from "./send";
import select from "./select";
import fillOpacity from "./fillOpacity";
import strokeOpacity from "./strokeOpacity";
import highlight from "./highlight";
import pencil from "./pencil";
import eraser from "./eraser";
import line from "./line";
import curve from "./curve";
import arrow from "./arrow";
import doubleArrow from "./doubleArrow";
import deleteSelection from "./delete";
import text from "./text";
import equation from "./equation";
import shape from "./shape";
import undo from "./undo";
import redo from "./redo";
import background from "./background";
import snap from "./snap";
import polygon from "./polygon";
import upload from "./upload";
import download from "./download";
import zoomIn from "./zoomIn";
import zoomOut from "./zoomOut";
import strokeColor from "./strokeColor";
import fillColor from "./fillColor";
import lineWidth from "./lineWidth";
import lineStyle from "./lineStyle";
import presentCurrentPageNow from "./presentCurrentPageNow";
import resetDefaults from "./resetDefaults";

export const commands: {
    [key in WhiteboardAction]: BaseCommand;
} = {
    [WhiteboardAction.addPage]: addPage,
    [WhiteboardAction.removePage]: removePage,
    [WhiteboardAction.selectPage]: selectPage,
    [WhiteboardAction.movePage]: movePage,
    [WhiteboardAction.uploadImage]: uploadImage,
    [WhiteboardAction.clear]: clear,
    [WhiteboardAction.send]: send,
    [WhiteboardAction.select]: select,
    [WhiteboardAction.fillOpacity]: fillOpacity,
    [WhiteboardAction.strokeOpacity]: strokeOpacity,
    [WhiteboardAction.highlight]: highlight,
    [WhiteboardAction.pencil]: pencil,
    [WhiteboardAction.eraser]: eraser,
    [WhiteboardAction.line]: line,
    [WhiteboardAction.curve]: curve,
    [WhiteboardAction.arrow]: arrow,
    [WhiteboardAction.doubleArrow]: doubleArrow,
    [WhiteboardAction.delete]: deleteSelection,
    [WhiteboardAction.text]: text,
    [WhiteboardAction.equation]: equation,
    [WhiteboardAction.shape]: shape,
    [WhiteboardAction.undo]: undo,
    [WhiteboardAction.redo]: redo,
    [WhiteboardAction.background]: background,
    [WhiteboardAction.snap]: snap,
    [WhiteboardAction.polygon]: polygon,
    [WhiteboardAction.upload]: upload,
    [WhiteboardAction.download]: download,
    [WhiteboardAction.zoomIn]: zoomIn,
    [WhiteboardAction.zoomOut]: zoomOut,
    [WhiteboardAction.strokeColor]: strokeColor,
    [WhiteboardAction.fillColor]: fillColor,
    [WhiteboardAction.lineWidth]: lineWidth,
    [WhiteboardAction.lineStyle]: lineStyle,
    [WhiteboardAction.makeCurrentPageLive]: presentCurrentPageNow,
    [WhiteboardAction.resetDefaults]: resetDefaults
};

import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const fillColor: BaseCommand = {
    execute(
        context: ZwibblerContext,
        props: {
            color: string;
            tool: WhiteboardAction;
        }
    ) {
        context.setColour(props.color, true);
        LogAction(context, props.tool, {
            fill_color_changed: props.color
        });
    }
};

export default fillColor;

import { Color } from "./Color";
import { LineStyle } from "./LineStyle";

export const DefaultProps = {
    strokeColor: Color.black,
    fillColor: Color.black,
    lineStyle: LineStyle.solid,
    strokeWidth: 5,
    fillOpacity: 0
};
